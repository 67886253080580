import { Locale, Placement } from 'react-joyride'
import userGuides from './userGuides.json'

export const PLAYGROUND_GUIDE = 'YCUNWxIAACAAS8jE'
export const CREATE_IDENTITY_GUIDE = 'YCJ4ChIAACAAQFXP'
export const ADDING_DELEGATE_GUIDE = 'YCUNshIAACEAS8pM'
export const CREATE_ASSETTYPE_GUIDE = 'YCUOGBIAACMAS8wZ'
export const CREATE_ASSET_GUIDE = 'YCUOfBIAACMAS83d'
export const CREATE_CLAIM_GUIDE = 'YCUOyRIAACIAS883'
export const ADDING_ENDORSEMENT_GUIDE = 'YCUPARIAACEAS9A6'

export interface UserGuideEntry {
  target: string
  placement: Placement
  content: string
  title: string
  locale?: Locale
}

export type UserGuidesType =
  | typeof PLAYGROUND_GUIDE
  | typeof CREATE_IDENTITY_GUIDE
  | typeof ADDING_DELEGATE_GUIDE
  | typeof CREATE_ASSETTYPE_GUIDE
  | typeof CREATE_ASSET_GUIDE
  | typeof CREATE_CLAIM_GUIDE
  | typeof ADDING_ENDORSEMENT_GUIDE

export interface UserGuide {
  run: boolean
  steps: UserGuideEntry[]
}

class GuideClient {
  // Indicates if the class is ready to return guides
  public ready: Promise<any>
  private guides: any = {}

  constructor() {
    this.ready = new Promise(resolve => {
      this.loadGuides().then(guides => {
        this.guides = guides
        resolve(undefined)
      })
    })
  }

  private async loadGuides() {
    const guideIds = [
      PLAYGROUND_GUIDE,
      CREATE_IDENTITY_GUIDE,
      ADDING_DELEGATE_GUIDE,
      CREATE_ASSETTYPE_GUIDE,
      CREATE_ASSET_GUIDE,
      CREATE_CLAIM_GUIDE,
      ADDING_ENDORSEMENT_GUIDE,
    ]
    const guides: any = {}
    for (const guideId of guideIds) {
      guides[guideId] = await this.formatGuide(userGuides, guideId)
    }
    return guides
  }

  private async formatGuide(guides: any, guide: string) {
    const entry = guides.results.find((e: any) => e.id === guide)
    const steps: UserGuideEntry[] = []
    for (const step of entry.data.body) {
      steps.push({
        target: step.primary.target,
        placement: step.primary.placement.toLowerCase(),
        content: step.primary.content[0].text,
        title: step.primary.title[0].text,
      })
    }
    steps[steps.length - 1].locale = {
      last: 'Finish',
    }

    return {
      run: entry.data.run,
      steps: steps,
    }
  }
  public async getGuide(guide: UserGuidesType) {
    await this.ready
    return this.guides[guide]
  }
}

const guideClient = new GuideClient()

export { guideClient }
