import { StoredIdentity } from 'model'

export class Requestor {
  identity: StoredIdentity
  delegatorId: string | undefined
  constructor(selectedIdentity: StoredIdentity, selectedDelegate?: StoredIdentity) {
    this.identity = selectedDelegate || selectedIdentity
    this.delegatorId = selectedDelegate ? selectedIdentity.address : undefined
  }

  getIdentity() {
    return this.identity
  }

  getIdentityId() {
    return this.identity.address
  }

  hasDelegator() {
    return this.delegatorId !== undefined ? true : false
  }

  getDelegatorId() {
    return this.delegatorId
  }
}
