import { select, takeEvery } from 'redux-saga/effects'
import { StoredUserPreference } from '../model'
import LocalStorage from '../LocalStorage'
import { AppState } from '../store'

export const USER_PREFERENCE = 'USER_PREFERNCE'

const defaultUserPreference: StoredUserPreference = {
  useExtension: false,
  useGuide: true,
}

export interface UserPreferenceAction {
  type: typeof USER_PREFERENCE
  carPurchase: StoredUserPreference
}

export function selectUserPreference(userPreference: StoredUserPreference | null) {
  return {
    type: USER_PREFERENCE,
    userPreference,
  }
}

const initialState: StoredUserPreference =
  JSON.parse(localStorage.getItem(LocalStorage.UserPreference) || 'null') || defaultUserPreference

export default function userPreferenceReducer(
  state = initialState,
  action: any
): StoredUserPreference | null {
  switch (action.type) {
    case USER_PREFERENCE:
      return action.userPreference

    default:
      return state
  }
}

export const getUserPreference = (state: AppState) => state.userPreference as StoredUserPreference

function* userPreferenceWorker() {
  const userPreference: StoredUserPreference = yield select(getUserPreference)
  localStorage.setItem(LocalStorage.UserPreference, JSON.stringify(userPreference))
}

export function* userPreferenceSaga() {
  yield takeEvery(USER_PREFERENCE, userPreferenceWorker)
}
