import { StoredClaim } from '../model'
import LocalStorage from '../LocalStorage'
import { AppState } from '../store'
import { select, takeEvery } from 'redux-saga/effects'
import { addOrReplace } from '../utils/arrayAndStringsUtils'

export const CLAIM_CREATED = 'CLAIM_CREATED'
export const ASSET_CLAIM_SEPARATOR = ': '

interface ClaimCreated {
  type: typeof CLAIM_CREATED
  claim: StoredClaim
}

export type ClaimAction = ClaimCreated

export function claimCreated(claim: StoredClaim): ClaimAction {
  return {
    type: CLAIM_CREATED,
    claim,
  }
}

const initialState: StoredClaim[] = JSON.parse(localStorage.getItem(LocalStorage.Claims) || '[]')

export default function claimReducer(state = initialState, action: ClaimAction): StoredClaim[] {
  switch (action.type) {
    case CLAIM_CREATED:
      return addOrReplace(state, [action.claim], c => c.claimant.authenticator.address + ':' + c.hashedClaim)
    default:
      return state
  }
}

export const getClaims = (state: AppState) => state.claims

function* claimCreatedWorker() {
  const state: StoredClaim[] = yield select(getClaims)
  localStorage.setItem(LocalStorage.Claims, JSON.stringify(state))
}

export function* claimCreatedSaga() {
  yield takeEvery(CLAIM_CREATED, claimCreatedWorker)
}
