import React from 'react'
import { List } from '@material-ui/core'
import GroupWorkIcon from '@material-ui/icons/GroupWorkRounded'
import { useSelector } from 'react-redux'
import { QUANTIFIABLE, StoredAssetType } from 'model'
import { makeStyles } from '@material-ui/styles'
import { getAssetTypes } from '../modules/assetTypes'
import { ItemWithProof } from './ItemWithProof'
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyRounded'
import CreateAssetType from './CreateAssetType'

const useStyles = makeStyles({
  container: {
    width: '50%',
  },
})

interface AssetTypeProps {
  id: StoredAssetType
}

const AssetType: React.FC<AssetTypeProps> = ({ id }) => {
  return (
    <ItemWithProof
      title={id.address}
      requestId={id.requestId}
      avatarIcon={id.type === QUANTIFIABLE ? <AttachMoneyIcon /> : <GroupWorkIcon />}
      preDetail={
        <>
          {id.type === QUANTIFIABLE ? `Scale: ${id.scale}` : ''}
          {id.type === QUANTIFIABLE ? <br /> : ''}
          Owner: {id.delegateIdentityId ? `${id.delegateIdentityId} on behalf of ${id.owner}` : id.owner}
        </>
      }
    />
  )
}

export interface ListAssetTypesProps {}

const ListAssetTypes: React.FC<ListAssetTypesProps> = () => {
  const classes = useStyles()
  const assetTypes = useSelector(getAssetTypes)

  return (
    <>
      <CreateAssetType />
      <List className={classes.container}>
        {assetTypes.map(i => (
          <AssetType id={i} key={i.requestId} />
        ))}
      </List>
    </>
  )
}

export default ListAssetTypes
