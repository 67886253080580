export const currentEnvironment = getEnvironment()

function getEnvironment() {
  const hostname = window.location.host.split('.')
  const env = hostname.length > 1 ? hostname[1] : ''
  switch (env as string) {
    case 'staging':
    case 'sandbox':
    case 'solutions':
    case 'acqua':
    case 'vienna-integration':
    case 'dev-master':
      return env
    default:
      return 'solutions'
  }
}

const environments = {
  staging: 'https://api.staging.iov42.net',
  sandbox: 'https://api.sandbox.poc.iov42.net',
  solutions: 'https://api.solutions.poc.iov42.net',
  acqua: 'https://api.aqua.de.iov42.net',
  'vienna-integration': 'https://api.vienna-integration.poc.iov42.net',
  'dev-master': 'https://master.dev.iov42.net',
}
export const environmentUri = environments[currentEnvironment as keyof typeof environments]
