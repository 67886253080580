import { select, takeEvery } from 'redux-saga/effects'
import { StoredIdentity } from '../model'
import LocalStorage from '../LocalStorage'
import { AppState } from '../store'
import { IDENTITY_NOT_FOUND, IDENTITY_SELECTED } from './selectedIdentity'

export const IDENTITY_CREATED = 'IDENTITY_CREATED'
export const DELETE_IDENTITY = 'DELETE_IDENTITY'

export interface IdentityCreated {
  type: typeof IDENTITY_CREATED
  identity: StoredIdentity
}

export interface DeleteIdentity {
  type: typeof DELETE_IDENTITY
  identity: StoredIdentity
}

export interface IdentityNotFound {
  type: typeof IDENTITY_NOT_FOUND
  identity: StoredIdentity
}

export interface IdentitySelected {
  type: typeof IDENTITY_SELECTED
  identity: StoredIdentity
}

export type IdentityAction = IdentityCreated | IdentityNotFound | IdentitySelected | DeleteIdentity

export function identityCreated(identity: StoredIdentity): IdentityAction {
  return {
    type: IDENTITY_CREATED,
    identity,
  }
}

export function deleteIdentity(identity: StoredIdentity): IdentityAction {
  return {
    type: DELETE_IDENTITY,
    identity,
  }
}

const initialState: StoredIdentity[] = JSON.parse(localStorage.getItem(LocalStorage.Identities) || '[]')

export default function identitiesReducer(state = initialState, action: IdentityAction): StoredIdentity[] {
  switch (action.type) {
    case IDENTITY_SELECTED:
      const setState = state.map(identity => {
        if (identity.address === action.identity.address) {
          identity.present = true
        }
        return identity
      })
      return setState
    case IDENTITY_NOT_FOUND:
      console.log('IDENTITY_NOT_FOUND' + action.identity.address)
      const newState = state.map(identity => {
        if (identity.address === action.identity.address) {
          identity.present = false
        }
        return identity
      })
      return newState
    case IDENTITY_CREATED:
      return [...state, action.identity]
    case DELETE_IDENTITY:
      const updState = state.filter(
        (identity: StoredIdentity) => identity.address !== action.identity.address
      )
      return updState
    default:
      return state
  }
}

export const getIdentities: (state: AppState) => StoredIdentity[] = (state: AppState) => state.identities

function* identityCreatedWorker() {
  const state: StoredIdentity[] = yield select(getIdentities)
  localStorage.setItem(LocalStorage.Identities, JSON.stringify(state))
}

export function* identityCreatedSaga() {
  yield takeEvery(IDENTITY_CREATED, identityCreatedWorker)
}

function* deleteIdentityWorker() {
  const state: StoredIdentity[] = yield select(getIdentities)
  localStorage.setItem(LocalStorage.Identities, JSON.stringify(state))
}

export function* deleteIdentitySaga() {
  yield takeEvery(DELETE_IDENTITY, deleteIdentityWorker)
}
