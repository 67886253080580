import React from 'react'
import { Field, Form, Formik } from 'formik'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoredAsset } from '../model'
import { useSelector } from 'react-redux'
import { getAssetTypes } from '../modules/assetTypes'
import { useTransferQuantity } from '../utils/useTransferQuantity'
import { getAssets } from '../modules/assets'
import DisplayError from './DisplayError'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonContainer: {
    paddingTop: '1em',
  },
  error: {
    marginTop: '1em',
    color: theme.palette.error.main,
  },
}))

export interface TransferQuantityProps {
  fromAsset: StoredAsset
}

const TransferQuantity: React.FC<TransferQuantityProps> = ({ fromAsset }) => {
  const classes = useStyles()
  const assetTypes = useSelector(getAssetTypes)
  const targetAccounts = useSelector(getAssets).filter(asset => {
    if (fromAsset.assetTypeId !== asset.assetTypeId) return false
    const assetType = assetTypes.find(at => at.address === asset.assetTypeId)
    return assetType && asset.address !== fromAsset.address
  })
  const [result, error, submit] = useTransferQuantity(fromAsset)

  return (
    <Formik initialValues={{ quantity: '', toAsset: '' }} onSubmit={submit}>
      {({ values, isSubmitting }) => (
        <Form className={classes.form}>
          <FormControl>
            <Field as={TextField} name="quantity" label="Quantity" />
          </FormControl>
          <FormControl>
            <InputLabel>To account:</InputLabel>
            <Field as={Select} name="toAsset" type="string" style={{ minWidth: 120 }}>
              {targetAccounts.map(toAsset => (
                <MenuItem key={toAsset.address} value={toAsset.address}>
                  {toAsset.address}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting || values.quantity === '' || values.toAsset === ''}
            >
              {isSubmitting ? <CircularProgress size={24} /> : 'Transfer Quantity'}
            </Button>
          </div>
          <DisplayError errorTitle={error} error={result.error} />
        </Form>
      )}
    </Formik>
  )
}

export default TransferQuantity
