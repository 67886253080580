export class Iov42 {
  webId = this.getUUID()
  callbacks = []

  onInitPluginFct = null

  onLockFct = null
  onUnlockFct = null
  onActivateIdentityFct = null

  constructor(onInitPluginFct) {
    this.onInitPluginFct = onInitPluginFct
    this.addWindowListner()
  }

  /* public callbacks */

  onInitPlugin(onInitPluginFct) {
    this.onInitPluginFct = onInitPluginFct
  }

  onLock(onLockFct) {
    this.onLockFct = onLockFct
  }

  onUnlock(onUnlockFct) {
    this.onUnlockFct = onUnlockFct
  }

  onActivateIdentity(onActivateIdentityFct) {
    this.onActivateIdentityFct = onActivateIdentityFct
  }

  /* public actions */

  signData(data, iovAddress = null) {
    return this.postMessage('signData', { data, iovAddress })
  }

  getAuthHeader(url) {
    return this.postMessage('getAuthHeader', url)
  }

  resetTimer() {
    return this.postMessage('resetTimer')
  }

  verifyClaim(data) {
    return this.postMessage('verifyClaim', data)
  }

  execPluginActions(actions) {
    return this.postMessage('execPluginActions', actions)
  }

  clearCallbacks() {
    this.callbacks = []
  }

  /* Getters */

  getNodeInfo() {
    return this.postMessage('getNodeInfo')
  }

  getHealthChecks() {
    return this.postMessage('getHealthChecks')
  }

  getProof(requestId, identityId, delegatorIdentityId = undefined) {
    return this.postMessage('getProof', {
      requestId,
      identityId,
      delegatorIdentityId,
    })
  }

  getRequest(requestId) {
    return this.postMessage('getRequest', {
      requestId,
    })
  }

  getTransactions(query, identityId, delegatorIdentityId = undefined) {
    return this.postMessage('getTransactions', {
      query,
      identityId,
      delegatorIdentityId,
    })
  }

  getAssetType(assetTypeId, identityId, delegatorIdentityId = undefined) {
    return this.postMessage('getAssetType', {
      assetTypeId,
      identityId,
      delegatorIdentityId,
    })
  }

  getAsset(assetId, assetTypeId, identityId, delegatorIdentityId = undefined) {
    return this.postMessage('getAsset', {
      assetId,
      assetTypeId,
      identityId,
      delegatorIdentityId,
    })
  }

  getIdentity(identityId, delegatorIdentityId = undefined) {
    return this.postMessage('getIdentity', {
      identityId,
      delegatorIdentityId,
    })
  }

  getPublicKey(identityId) {
    return this.postMessage('getPulicKey', {
      identityId,
    })
  }

  getIdentityClaims(identityId, signingIdentityId, delegatorIdentityId = undefined) {
    return this.postMessage('getIdentityClaims', {
      identityId,
      signingIdentityId,
      delegatorIdentityId,
    })
  }

  getIdentityClaim(identityId, signingIdentityId, claim, delegatorIdentityId = undefined) {
    return this.postMessage('getIdentityClaim', {
      identityId,
      signingIdentityId,
      claim,
      delegatorIdentityId,
    })
  }

  getIdentityClaimEndorsement(
    identityId,
    signingIdentityId,
    claim,
    endorserId,
    delegatorIdentityId = undefined
  ) {
    return this.postMessage('getIdentityClaimEndorsement', {
      identityId,
      signingIdentityId,
      claim,
      endorserId,
      delegatorIdentityId,
    })
  }

  getAssetTypeClaims(assetTypeId, identityId, delegatorIdentityId = undefined) {
    return this.postMessage('getAssetTypeClaims', {
      assetTypeId,
      identityId,
      delegatorIdentityId,
    })
  }

  getAssetTypeClaim(assetTypeId, claim, identityId, delegatorIdentityId = undefined) {
    return this.postMessage('getAssetTypeClaim', {
      assetTypeId,
      claim,
      identityId,
      delegatorIdentityId,
    })
  }

  getAssetTypeClaimEndorsement(assetTypeId, claim, endorserId, identityId, delegatorIdentityId = undefined) {
    return this.postMessage('getAssetTypeClaimEndorsement', {
      assetTypeId,
      claim,
      endorserId,
      identityId,
      delegatorIdentityId,
    })
  }

  getAssetClaims(assetId, assetTypeId, identityId, delegatorIdentityId = undefined) {
    return this.postMessage('getAssetClaims', {
      assetId,
      assetTypeId,
      identityId,
      delegatorIdentityId,
    })
  }

  getAssetClaim(assetId, assetTypeId, claim, identityId, delegatorIdentityId = undefined) {
    return this.postMessage('getAssetClaim', {
      assetId,
      assetTypeId,
      claim,
      identityId,
      delegatorIdentityId,
    })
  }

  getAssetClaimEndorsement(
    assetId,
    assetTypeId,
    claim,
    endorserId,
    identityId,
    delegatorIdentityId = undefined
  ) {
    return this.postMessage('getAssetClaimEndorsement', {
      assetId,
      assetTypeId,
      claim,
      endorserId,
      identityId,
      delegatorIdentityId,
    })
  }

  getDelegates(identityId) {
    return this.postMessage('getDelegates', {
      identityId,
    })
  }

  /* private methods */

  addWindowListner() {
    window.addEventListener('message', messageObj => {
      if (messageObj.data && messageObj.data.routing && messageObj.data.routing.target !== 'iov42Service') {
        return null
      }
      const message = messageObj.data
      if (message) {
        if (message.routing && message.routing.method === 'initPlugin' && this.onInitPluginFct) {
          this.onInitPluginFct(message.data)
        } else {
          if (message.source === 'iov42') {
            const e = message.event
            const d = message.data
            if (e === 'activateIdentity' && this.onActivateIdentityFct) {
              this.onActivateIdentityFct(d)
            } else if (e === 'unlock' && this.onUnlockFct) {
              this.onUnlockFct(d)
            } else if (e === 'lock' && this.onLockFct) {
              this.onLockFct(d)
            }
          }

          if (message.routing && message.routing.destination === this.webId) {
            this.handleMessageCb(message)
          }
        }
      }
    })
  }

  handleMessageCb(message) {
    if (message.routing.callbackUUID && this.callbacks[message.routing.callbackUUID]) {
      if (message.resolve) {
        this.callbacks[message.routing.callbackUUID].resolve(message.data)
      } else {
        this.callbacks[message.routing.callbackUUID].reject(message.data)
      }
      delete this.callbacks[message.routing.callbackUUID]
    }
  }

  postMessage(method, data = null) {
    const message = {
      routing: {
        webId: this.webId,
        destination: 'iov42',
        method: method,
        component: 'background',
      },
    }

    if (data) {
      message['data'] = data
    }

    const uuid = this.getUUID()
    message.routing['callbackUUID'] = uuid

    window.postMessage(message, '*')

    return new Promise((resolve, reject) => {
      this.callbacks[uuid] = { resolve, reject }
    })
  }

  getUUID() {
    let dt = new Date().getTime()
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid
  }
}
