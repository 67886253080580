import React, { Fragment } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { Route, Switch, useParams } from 'react-router'
import Locations from 'Locations'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, Tabs, Theme } from '@material-ui/core'
import { push } from 'connected-react-router'
import PeopleIcon from '@material-ui/icons/PeopleAlt'
import Chip from '@material-ui/core/Chip'
import { getSelectedDelegate } from 'modules/selectedDelegate'
import { getSelectedIdentity } from 'modules/selectedIdentity'
import { noop } from '../noop'
import { ASSET_TYPES_SUBJECT, ASSETS_SUBJECT, IDENTITIES_SUBJECT } from './CreateClaim'

const lightColor = 'rgba(255, 255, 255, 0.7)'

const useStyles = makeStyles((theme: Theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
}))

interface Submenu {
  title: string
  location: string
  disabled?: boolean
  buildUri?: (params: any) => string
}

interface Section {
  title: string
  submenus: Submenu[]
}

const sections: Section[] = [
  {
    title: 'Identities',
    submenus: [
      { title: 'List', location: Locations.ListIdentities.uri },
      { title: 'View', location: Locations.ViewIdentity.uri, disabled: true },
    ],
  },
  {
    title: 'Delegates',
    submenus: [{ title: 'List', location: Locations.ListDelegateIdentities.uri }],
  },
  {
    title: 'Asset Types',
    submenus: [{ title: 'List', location: Locations.ListAssetTypes.uri }],
  },
  {
    title: 'Assets',
    submenus: [
      { title: 'List', location: Locations.ListAssets.uri },
      { title: 'View', location: Locations.ViewAsset.uri, disabled: true },
    ],
  },
  {
    title: 'Claims',
    submenus: [
      { title: 'Identities', location: Locations.ListClaims.buildUri(IDENTITIES_SUBJECT) },
      { title: 'Asset types', location: Locations.ListClaims.buildUri(ASSET_TYPES_SUBJECT) },
      { title: 'Assets', location: Locations.ListClaims.buildUri(ASSETS_SUBJECT) },
      { title: 'View', location: Locations.ViewClaim.uri, disabled: true },
    ],
  },
  {
    title: 'Endorsements',
    submenus: [{ title: 'List', location: Locations.ListEndorsements.uri }],
  },
  {
    title: 'Requests',
    submenus: [
      {
        title: 'Proof',
        location: Locations.ViewRequest.uri,
        buildUri: Locations.ViewRequest.buildUri,
      },
      {
        title: 'Visualise',
        location: Locations.VisualiseProof.uri,
        buildUri: Locations.VisualiseProof.buildUri,
      },
    ],
  },
  {
    title: 'Settings',
    submenus: [],
  },
  {
    title: 'Car purchase',
    submenus: [{ title: 'List', location: Locations.CarPurchase.uri }],
  },
]

interface SectionHeaderProps {
  section: Section
  selected: Submenu
}
const SectionHeader: React.FC<SectionHeaderProps> = ({ section, selected }) => {
  const classes = useStyles()
  const selectedIdentity = useSelector(getSelectedIdentity)
  const selectedDelegate = useSelector(getSelectedDelegate)
  const dispatch = useDispatch()
  const params = useParams()

  const authenticatedAs = selectedIdentity == null ? 'No identity selected' : selectedIdentity.address
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs />
            <Grid item>
              <Chip
                icon={
                  <PeopleIcon
                    style={{
                      color: '#00251c',
                    }}
                  />
                }
                label={authenticatedAs}
                style={{
                  color: '#00251c',
                }}
                onClick={_ => dispatch(push(Locations.ListIdentities.uri))}
              />
            </Grid>
            {selectedDelegate ? (
              <Fragment>
                <Grid item>
                  <Typography
                    style={{
                      color: '#b6ffe1',
                    }}
                  >
                    via
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    color={'primary'}
                    icon={
                      <PeopleIcon
                        style={{
                          color: '#b6ffe1',
                        }}
                      />
                    }
                    label={selectedDelegate.address}
                    style={{
                      color: '#b6ffe1',
                    }}
                    onClick={_ => dispatch(push(Locations.ListIdentities.uri))}
                  />
                </Grid>
              </Fragment>
            ) : (
              <Fragment />
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        style={{
          color: '#b6ffe1',
        }}
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {section.title}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Tabs value={section.submenus.indexOf(selected)} textColor="inherit">
          {section.submenus.map((m, i) => (
            <Tab
              key={m.title}
              textColor="inherit"
              style={{
                color: '#b6ffe1',
              }}
              label={m.title}
              disabled={!!m.disabled && i !== section.submenus.indexOf(selected)}
              onClick={m.disabled ? noop : () => dispatch(push(m.buildUri ? m.buildUri(params) : m.location))}
            />
          ))}
        </Tabs>
      </AppBar>
    </React.Fragment>
  )
}

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  return (
    <Switch>
      {sections.flatMap(s =>
        s.submenus.map(m => (
          <Route
            key={`${s.title}-${m.title}`}
            exact
            path={m.location}
            render={() => <SectionHeader section={s} selected={m} />}
          />
        ))
      )}
    </Switch>
  )
}

export default Header
