import {
  IAddDelegateRequest,
  IAuthorisedRequest,
  ICreateAssetTypeRequest,
  ICreateAssetRequest,
  ICreateClaimsRequest,
  IEndorseClaimsRequest,
  IKeyPairData,
  ITransferOwnership,
  ITransferQuantity,
  ITransferRequest,
  PlatformClient,
  PlatformUtils,
  ProtocolIdType,
} from 'core-sdk-typescript'
import { environmentUri } from '../config'
import { StoredAsset, StoredIdentity } from 'model'
import { Requestor } from './requestor'

export class ClientLib {
  platformUtils: PlatformUtils
  platformClient: PlatformClient

  constructor() {
    this.platformUtils = new PlatformUtils()
    this.platformClient = new PlatformClient(environmentUri)
  }

  createKeyPair(identity: StoredIdentity) {
    const keyPair: IKeyPairData = {
      pubKeyBase64: identity.publicKey,
      prvKeyBase64: identity.privateKey,
      protocolId: identity.protocol,
      identityId: identity.address,
    }
    return keyPair
  }

  async getAssetType(assetType: string, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    return await this.platformClient.getAssetType(assetType, keyPair, requestor.getDelegatorId())
  }

  async getAsset(assetId: string, assetTypeId: string, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    return await this.platformClient.getAsset(assetId, assetTypeId, keyPair, requestor.getDelegatorId())
  }

  async getIdentityClaim(subjectId: string, claim: string, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    return await this.platformClient.getIdentityClaim(subjectId, claim, keyPair, requestor.getDelegatorId())
  }

  async getAssetTypeClaim(subjectId: string, claim: string, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    return await this.platformClient.getAssetTypeClaim(subjectId, claim, keyPair, requestor.getDelegatorId())
  }

  async getAssetClaim(subjectId: string, subjectTypeId: string, claim: string, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    return await this.platformClient.getAssetClaim(
      subjectId,
      subjectTypeId,
      claim,
      keyPair,
      requestor.getDelegatorId()
    )
  }

  async getIdentityClaimEndorsement(
    subjectId: string,
    claim: string,
    endorserId: string,
    requestor: Requestor
  ) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    return await this.platformClient.getIdentityClaimEndorsement(
      subjectId,
      claim,
      endorserId,
      keyPair,
      requestor.getDelegatorId()
    )
  }

  async getAssetTypeClaimEndorsement(
    subjectId: string,
    claim: string,
    endorserId: string,
    requestor: Requestor
  ) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    return await this.platformClient.getAssetTypeClaimEndorsement(
      subjectId,
      claim,
      endorserId,
      keyPair,
      requestor.getDelegatorId()
    )
  }

  async getAssetClaimEndorsement(
    subjectId: string,
    subjectTypeId: string,
    claim: string,
    endorserId: string,
    requestor: Requestor
  ) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    return await this.platformClient.getAssetClaimEndorsement(
      subjectId,
      subjectTypeId,
      claim,
      endorserId,
      keyPair,
      requestor.getDelegatorId()
    )
  }

  async getIdentity(requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    return await this.platformClient.getIdentity(requestor.getIdentityId(), keyPair)
  }

  async createIdentity(protocolId: ProtocolIdType, identityId: string) {
    const keyPair = this.platformUtils.generateKeypairWithProtocolId(protocolId, identityId)
    const request = {
      identityId: keyPair.identityId,
      publicCredentials: {
        key: keyPair.pubKeyBase64,
        protocolId: keyPair.protocolId,
      },
    }
    const response = await this.platformClient.createIdentity(request, keyPair)
    return {
      pubKeyBase64: keyPair.pubKeyBase64,
      prvKeyBase64: keyPair.prvKeyBase64,
      proof: response.proof,
      requestId: response.requestId,
    }
  }

  async createAssetType(input: any, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const request: ICreateAssetTypeRequest = {
      assetTypeId: input.address,
      type: input.type,
    }
    if (input.type === 'Quantifiable') {
      request.scale = Number(input.scale)
    }

    return await this.platformClient.createAssetType(request, keyPair, requestor.getDelegatorId())
  }

  async createAsset(input: any, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const request: ICreateAssetRequest = {
      assetId: input.address,
      assetTypeId: input.assetTypeId,
    }
    if (input.quantity !== '') {
      request.quantity = input.quantity
    }

    return await this.platformClient.createAsset(request, keyPair, requestor.getDelegatorId())
  }

  async assignDelegate(delegator: StoredIdentity, delegate: StoredIdentity) {
    const delegatorKeyPair: IKeyPairData = this.createKeyPair(delegator)
    const delegateKeyPair: IKeyPairData = this.createKeyPair(delegate)
    const request: IAddDelegateRequest = {
      _type: 'AddDelegateRequest',
      delegateIdentityId: delegate.address,
      delegatorIdentityId: delegator.address,
    }
    const signedRequest: IAuthorisedRequest = this.platformClient.prepareRequest(request, delegatorKeyPair)
    const finalRequest: IAuthorisedRequest = this.platformClient.addSignatureRequest(
      signedRequest,
      delegateKeyPair
    )
    return await this.platformClient.addDelegate(finalRequest, delegatorKeyPair)
  }

  async getHealthChecks() {
    return await this.platformClient.getHealthChecks()
  }

  async createIdentityClaims(input: any, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const claims = [input.plaintextClaim]
    const request: ICreateClaimsRequest = {
      claims: this.platformUtils.createClaimsHashArray(claims),
      subjectId: input.subjectId,
    }
    return await this.platformClient.createIdentityClaims(
      request,
      claims,
      keyPair,
      requestor.getDelegatorId()
    )
  }

  async createAssetTypeClaims(input: any, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const claims = [input.plaintextClaim]
    const request: ICreateClaimsRequest = {
      claims: this.platformUtils.createClaimsHashArray(claims),
      subjectId: input.subjectId,
    }
    return await this.platformClient.createAssetTypeClaims(
      request,
      claims,
      keyPair,
      requestor.getDelegatorId()
    )
  }

  async createAssetClaims(input: any, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const claims = [input.plaintextClaim]
    const request: ICreateClaimsRequest = {
      claims: this.platformUtils.createClaimsHashArray(claims),
      subjectId: input.subjectId,
      subjectTypeId: input.subjectTypeId,
    }
    return await this.platformClient.createAssetClaims(request, claims, keyPair, requestor.getDelegatorId())
  }

  async createIdentityClaimEndorsement(input: any, requestor: Requestor) {
    const claimantKeyPair: IKeyPairData = this.createKeyPair(input.claimant.authenticator)
    const endorserKeyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const delegatorId = requestor.getDelegatorId()
    const claims = [input.plaintextClaim]
    const endorsements = this.platformUtils.createEndorsementsObject(input.subjectId, claims, endorserKeyPair)
    const request: IEndorseClaimsRequest = {
      _type: 'CreateIdentityEndorsementsRequest',
      endorserId: delegatorId || endorserKeyPair.identityId,
      endorsements,
      subjectId: input.subjectId,
    }
    const signedRequest = this.platformClient.prepareRequest(request, claimantKeyPair)
    const finalRequest = this.platformClient.addSignatureRequest(signedRequest, endorserKeyPair, delegatorId)
    return await this.platformClient.endorseAssetTypeClaims(finalRequest, [], endorserKeyPair)
  }

  async createAssetTypeClaimEndorsement(input: any, requestor: Requestor) {
    const claimantKeyPair: IKeyPairData = this.createKeyPair(input.claimant.authenticator)
    const endorserKeyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const delegatorId = requestor.getDelegatorId()
    const claims = [input.plaintextClaim]
    const endorsements = this.platformUtils.createEndorsementsObject(input.subjectId, claims, endorserKeyPair)
    const request: IEndorseClaimsRequest = {
      _type: 'CreateAssetTypeEndorsementsRequest',
      endorserId: delegatorId || endorserKeyPair.identityId,
      endorsements,
      subjectId: input.subjectId,
    }
    const signedRequest = this.platformClient.prepareRequest(request, claimantKeyPair)
    const finalRequest = this.platformClient.addSignatureRequest(signedRequest, endorserKeyPair, delegatorId)
    return await this.platformClient.endorseAssetTypeClaims(finalRequest, [], endorserKeyPair)
  }

  async createAssetClaimEndorsement(input: any, requestor: Requestor) {
    const claimantKeyPair: IKeyPairData = this.createKeyPair(input.claimant.authenticator)
    const endorserKeyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const delegatorId = requestor.getDelegatorId()
    const claims = [input.plaintextClaim]
    const endorsements = this.platformUtils.createEndorsementsObject(
      input.subjectId,
      claims,
      endorserKeyPair,
      input.subjectTypeId
    )
    const request: IEndorseClaimsRequest = {
      _type: 'CreateAssetEndorsementsRequest',
      endorserId: delegatorId || endorserKeyPair.identityId,
      endorsements,
      subjectId: input.subjectId,
      subjectTypeId: input.subjectTypeId,
    }
    const signedRequest = this.platformClient.prepareRequest(request, claimantKeyPair)
    const finalRequest = this.platformClient.addSignatureRequest(signedRequest, endorserKeyPair, delegatorId)
    return await this.platformClient.endorseAssetClaims(finalRequest, [], endorserKeyPair)
  }

  async getProof(requestId: string, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    return await this.platformClient.getProof(requestId, keyPair)
  }

  async transferOwnership(input: any, asset: StoredAsset, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const transfer: ITransferOwnership = {
      assetId: asset.address,
      assetTypeId: asset.assetTypeId,
      fromIdentityId: asset.owner,
      toIdentityId: input.toIdentity,
    }
    const request: ITransferRequest = {
      _type: 'TransfersRequest',
      transfers: [transfer],
    }

    const signedRequest: IAuthorisedRequest = this.platformClient.prepareRequest(
      request,
      keyPair,
      requestor.getDelegatorId()
    )
    return await this.platformClient.transferAssets(signedRequest, keyPair)
  }

  async transferQuantity(input: any, account: StoredAsset, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const transfer: ITransferQuantity = {
      assetTypeId: account.assetTypeId,
      fromAssetId: account.address,
      quantity: input.quantity,
      toAssetId: input.toAsset,
    }
    const request: ITransferRequest = {
      _type: 'TransfersRequest',
      transfers: [transfer],
    }
    const signedRequest: IAuthorisedRequest = this.platformClient.prepareRequest(
      request,
      keyPair,
      requestor.getDelegatorId()
    )
    return await this.platformClient.transferAssets(signedRequest, keyPair)
  }

  async addQuantity(input: any, account: StoredAsset, requestor: Requestor) {
    const keyPair: IKeyPairData = this.createKeyPair(requestor.getIdentity())
    const request: ICreateAssetRequest = {
      assetId: account.address,
      assetTypeId: account.assetTypeId,
      quantity: input.quantity,
    }
    return await this.platformClient.addAssetQuantity(request, keyPair, requestor.getDelegatorId())
  }
}

const clientLib = new ClientLib()

export { clientLib }
