import React from 'react'
import { Field, Form, Formik } from 'formik'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { AssetType, QUANTIFIABLE, StoredAssetType, UNIQUE } from 'model'
import { assetTypeCreated } from 'modules/assetTypes'
import { Input, useCreate } from '../utils/useCreate'
import { sleep } from '../utils/sleep'
import DisplayError from './DisplayError'
import { client } from '../client/client'
import { StoredIdentity } from 'model'
import { CREATE_ASSETTYPE_GUIDE } from '../client/guide'
import { UserGuide } from './UserGuide'

interface AssetTypeChoice {
  type: AssetType['type']
  address: string
  scale: string
}

async function createAssetType({
  input,
  selectedIdentity,
  selectedDelegate,
}: Input<AssetTypeChoice>): Promise<StoredAssetType> {
  if (selectedIdentity === null) {
    throw new Error('No identity selected')
  }

  const createResponse = await client.createAssetType(
    input,
    selectedIdentity as StoredIdentity,
    selectedDelegate as StoredIdentity
  )
  await sleep(1000)
  const response = await client.getAssetType(
    input.address,
    selectedIdentity as StoredIdentity,
    selectedDelegate as StoredIdentity
  )

  return {
    proof: createResponse.proof,
    requestId: createResponse.requestId,
    owner: response.ownerId,
    delegateIdentityId: response.delegateIdentityId,
    ...input,
  } as StoredAssetType
}

export interface CreateAssetTypeProps {}

const CreateAssetType: React.FC<CreateAssetTypeProps> = () => {
  const [result, error, submit] = useCreate(createAssetType, assetTypeCreated)
  const assetType: AssetTypeChoice = {
    type: UNIQUE,
    address: '',
    scale: '',
  }

  return (
    <div>
      <UserGuide id={CREATE_ASSETTYPE_GUIDE} />
      <Formik initialValues={assetType} onSubmit={submit}>
        {({ values, isSubmitting }) => (
          <Form>
            <div className="address">
              <FormControl style={{ display: 'block' }}>
                <Field as={TextField} name="address" type="string" label="Address" />
              </FormControl>
            </div>
            <br />
            <div className="type">
              <FormControl style={{ display: 'block' }}>
                <InputLabel>Behavior</InputLabel>
                <Field as={Select} name="type" type="string">
                  <MenuItem value={UNIQUE}>{UNIQUE}</MenuItem>
                  <MenuItem value={QUANTIFIABLE}>{QUANTIFIABLE}</MenuItem>
                </Field>
              </FormControl>
            </div>
            <div className="scale">
              <FormControl style={{ display: 'block' }}>
                <Field
                  as={TextField}
                  name="scale"
                  type="number"
                  label="Scale"
                  disabled={values.type === UNIQUE}
                />
              </FormControl>
            </div>
            <div style={{ paddingTop: 15 }}>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} /> : 'Create'}
              </Button>
            </div>
            <DisplayError errorTitle={error} error={result.error} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateAssetType
