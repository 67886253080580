import React from 'react'
import { useSelector } from 'react-redux'
import { StoredClaim, StoredEndorsement } from '../model'
import { Field, Form, Formik } from 'formik'
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { getClaims } from 'modules/claims'
import { sleep } from 'utils/sleep'
import { endorsementCreated } from '../modules/endorsements'
import { Input, useCreate } from 'utils/useCreate'
import { SubjectType } from './CreateClaim'
import DisplayError from './DisplayError'
import { client } from '../client/client'
import { clientLib } from '../client/clientLib'
import { ASSET_CLAIM_SEPARATOR } from '../modules/claims'
import { StoredIdentity } from 'model'
import { ADDING_ENDORSEMENT_GUIDE } from '../client/guide'
import { UserGuide } from './UserGuide'

interface EndorsementChoice {
  subjectAndClaim: string
  claims: StoredClaim[]
}

export const IDENTITIES_SUBJECT = 'identities'
export const ASSET_TYPES_SUBJECT = 'asset-types'
export const ASSETS_SUBJECT = 'assets'

async function createIdentityClaimEndorsement(
  input: any,
  selectedIdentity: StoredIdentity,
  selectedDelegate: StoredIdentity
) {
  const createResponse = await client.createIdentityClaimEndorsement(
    input,
    selectedIdentity,
    selectedDelegate
  )
  await sleep(1000)
  const endorsementResponse = await client.getIdentityClaimEndorsement(
    input.subjectId,
    clientLib.platformUtils.hash('sha256', input.plaintextClaim),
    selectedIdentity.address,
    selectedIdentity,
    selectedDelegate
  )
  return { createResponse, endorsementResponse }
}

async function createAssetTypeClaimEndorsement(
  input: any,
  selectedIdentity: StoredIdentity,
  selectedDelegate: StoredIdentity
) {
  const createResponse = await client.createAssetTypeClaimEndorsement(
    input,
    selectedIdentity,
    selectedDelegate
  )
  await sleep(1000)
  const endorsementResponse = await client.getAssetTypeClaimEndorsement(
    input.subjectId,
    clientLib.platformUtils.hash('sha256', input.plaintextClaim),
    selectedIdentity.address,
    selectedIdentity,
    selectedDelegate
  )
  return { createResponse, endorsementResponse }
}

async function createAssetClaimEndorsement(
  input: any,
  selectedIdentity: StoredIdentity,
  selectedDelegate: StoredIdentity
) {
  const parts = input.subjectId.split(ASSET_CLAIM_SEPARATOR)
  const newInput = {
    plaintextClaim: input.plaintextClaim,
    claimant: input.claimant,
    subjectId: parts[1],
    subjectTypeId: parts[0],
  }
  const createResponse = await client.createAssetClaimEndorsement(
    newInput,
    selectedIdentity,
    selectedDelegate
  )
  await sleep(1000)
  const endorsementResponse = await client.getAssetClaimEndorsement(
    newInput.subjectId,
    newInput.subjectTypeId,
    clientLib.platformUtils.hash('sha256', input.plaintextClaim),
    selectedIdentity.address,
    selectedIdentity,
    selectedDelegate
  )
  return { createResponse, endorsementResponse }
}

async function createEndorsement({
  input,
  selectedIdentity,
  selectedDelegate,
}: Input<EndorsementChoice>): Promise<StoredEndorsement> {
  if (selectedIdentity === null) {
    throw new Error('No identity selected')
  }

  const details = extractEndorsement(input.subjectAndClaim)
  if (!details) {
    throw new Error(`Couldn't get selected endorsement details, internal error`)
  }

  const claim = input.claims.find(claim => makeEndorsement(claim) === input.subjectAndClaim)
  if (claim == null) {
    throw new Error('No such claim was found, internal error')
  }

  const endorser = selectedDelegate || selectedIdentity
  if (!endorser) {
    throw new Error('Selected identity not found')
  }

  const endorsementCreators = {
    [IDENTITIES_SUBJECT]: createIdentityClaimEndorsement,
    [ASSET_TYPES_SUBJECT]: createAssetTypeClaimEndorsement,
    [ASSETS_SUBJECT]: createAssetClaimEndorsement,
  }

  const newInput = {
    plaintextClaim: details.plaintextClaim,
    claimant: claim.claimant,
    subjectId: details.subjectId,
  }
  const response = await endorsementCreators[details.subjectType](
    newInput,
    selectedIdentity,
    selectedDelegate as StoredIdentity
  )

  return {
    claim,
    endorser: {
      authenticator: selectedIdentity,
      delegate: selectedDelegate,
    },
    signature: response.endorsementResponse.endorsement,
    delegateIdentityId: response.endorsementResponse.delegateIdentityId,
    requestId: response.createResponse.requestId,
    proof: response.createResponse.proof,
  }
}

export interface CreateEndorsementProps {}

interface EndorsementDetails {
  subjectType: SubjectType
  subjectId: string
  hashedClaim: string
  plaintextClaim: string
}

const endorsementRegex = /^([^;]+);([^;]+);([^;]+);(.+)$/
function makeEndorsement(claim: StoredClaim): string {
  return `${claim.subjectType};${claim.subjectId};${claim.hashedClaim};${claim.plaintextClaim}`
}
function extractEndorsement(endorsement: string): EndorsementDetails | null {
  const matches = endorsementRegex.exec(endorsement)
  if (!matches) {
    return null
  }

  return {
    subjectType: matches[1] as SubjectType,
    subjectId: matches[2],
    hashedClaim: matches[3],
    plaintextClaim: matches[4],
  }
}

const CreateEndorsement: React.FC<CreateEndorsementProps> = () => {
  const [result, error, submit] = useCreate(createEndorsement, endorsementCreated)
  const claims = useSelector(getClaims)

  const initialValues = {
    subjectAndClaim: '',
    claims,
  }

  return (
    <div>
      <UserGuide id={ADDING_ENDORSEMENT_GUIDE} />
      <Formik initialValues={initialValues} onSubmit={submit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="claim">
              <FormControl style={{ display: 'block', marginBottom: '5px' }}>
                <InputLabel>Claim</InputLabel>
                <Field as={Select} name="subjectAndClaim" type="string" style={{ minWidth: 120 }}>
                  {claims.map(claim => (
                    <MenuItem key={claim.requestId} value={makeEndorsement(claim)}>
                      {claim.subjectId} - {claim.plaintextClaim}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </div>
            <div style={{ paddingTop: 15 }}>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} /> : 'Endorse'}
              </Button>
            </div>
            <DisplayError errorTitle={error} error={result.error} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateEndorsement
