import { SubjectType } from './components/CreateClaim'

const Locations = {
  Home: {
    uri: '/',
  },
  ViewIdentity: {
    uri: '/identities/view/:id',
    buildUri: ({ id }: { id: string }) => `/identities/view/${id}`,
  },
  ListIdentities: {
    uri: '/identities/list',
  },
  ListDelegateIdentities: {
    uri: '/delegates/list',
  },
  ViewAsset: {
    uri: '/asset-types/:typeId/assets/view/:id',
    buildUri: ({ typeId, id }: { typeId: string; id: string }) => `/asset-types/${typeId}/assets/view/${id}`,
  },
  ListAssetTypes: {
    uri: '/asset-types/list',
  },
  ListAssets: {
    uri: '/assets/list',
  },
  ViewRequest: {
    uri: '/requests/view/:id',
    buildUri: ({ id }: { id: string }) => `/requests/view/${id}`,
  },
  VisualiseProof: {
    uri: '/requests/visualise/:id',
    buildUri: ({ id }: { id: string }) => `/requests/visualise/${id}`,
  },
  Authentication: {
    uri: '/authentication',
  },
  ViewClaim: {
    uri: '/claims/view/:requestId',
    buildUri: ({ requestId }: { requestId: string }) => `/claims/view/${requestId}`,
  },
  ListClaims: {
    uri: '/:subjectType/claims/list',
    buildUri: (subjectType: SubjectType) => `/${subjectType}/claims/list/`,
  },
  ListEndorsements: {
    uri: '/endorsements/list',
  },
  CarPurchase: {
    uri: '/usecases/carpurchase',
  },
}

export default Locations
