import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { push } from 'connected-react-router'
import Locations from '../Locations'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { noop } from '../noop'

export interface ItemWithProofProps {
  onClick?: () => void
  title: string
  preDetail?: JSX.Element
  requestId: string
  buttons?: JSX.Element
  avatarIcon: JSX.Element
}

export const ItemWithProof: React.FC<ItemWithProofProps> = ({
  onClick,
  title,
  preDetail,
  requestId,
  buttons,
  avatarIcon,
}) => {
  const dispatch = useDispatch()
  return (
    <ListItem button onClick={onClick || noop}>
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: 'secondary' }}>{avatarIcon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <>
            {preDetail ? (
              <>
                {preDetail}
                <br />
              </>
            ) : (
              ''
            )}
            Request id: {requestId}
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton
          edge="start"
          aria-label="proof"
          onClick={() => dispatch(push(Locations.ViewRequest.buildUri({ id: requestId })))}
        >
          <ReceiptIcon />
        </IconButton>
        {buttons}
      </ListItemSecondaryAction>
    </ListItem>
  )
}
