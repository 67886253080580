import { MutationResult, useMutation } from 'react-query'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedIdentity } from '../modules/selectedIdentity'
import { getSelectedDelegate } from '../modules/selectedDelegate'
import { StoredAsset, StoredIdentity, StoredUniqueAsset } from '../model'
import { sleep } from './sleep'
import { FormikHelpers } from 'formik'
import { assetsCreatedOrModified } from '../modules/assets'
import { getIdentities } from '../modules/identities'
import { client } from '../client/client'

export interface Input {
  toIdentity: string
}

export interface MutateInput {
  input: Input
  asset: StoredAsset
  allIdentities: StoredIdentity[]
  selectedIdentity: StoredIdentity | null
  selectedDelegate: StoredIdentity | null
}

async function transferOnwership(i: MutateInput): Promise<StoredUniqueAsset[]> {
  if (i.selectedIdentity === null) {
    throw new Error('No identity selected')
  }
  const addressToFind = i.selectedDelegate ? i.selectedDelegate.address : i.selectedIdentity.address
  const identity = i.allIdentities.find(id => id.address === addressToFind)
  if (!identity) {
    throw new Error('Selected identity not found')
  }
  const transferOwnershipResponse = await client.transferOwnership(
    i.input,
    i.asset,
    i.selectedIdentity,
    i.selectedDelegate as StoredIdentity
  )

  await sleep(1000)
  const toIdentity = findToIdentity(i)
  if (!toIdentity) {
    throw new Error('To identity not found')
  }

  const toAssetResponse = await client.getAsset(i.asset.address, i.asset.assetTypeId, toIdentity)

  return [
    {
      proof: transferOwnershipResponse.proof,
      requestId: transferOwnershipResponse.requestId,
      owner: toAssetResponse.ownerId,
      address: toAssetResponse.assetId,
      assetTypeId: toAssetResponse.assetTypeId,
    },
  ]
}

function findToIdentity(i: MutateInput): StoredIdentity {
  const toIdentity = i.allIdentities.find(id => id.address === i.input.toIdentity)
  if (toIdentity === undefined) {
    throw new Error('Could not find target asset owner identity')
  }
  return toIdentity
}

export function useTransferOwnership(
  asset: StoredAsset
): [
  MutationResult<StoredUniqueAsset[], Error>,
  string,
  (input: Input, formik: FormikHelpers<Input>) => Promise<StoredUniqueAsset[] | undefined>
] {
  const [mutate, result] = useMutation(transferOnwership)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const selectedIdentity = useSelector(getSelectedIdentity)
  const selectedDelegate = useSelector(getSelectedDelegate)

  // we need to get all assets and identities to allow the use of target asset credentials to retrieve it
  const allIds = useSelector(getIdentities)

  const doIt = useCallback(
    async (input: Input, formik: FormikHelpers<Input>) => {
      setError('')
      try {
        const modifiedAssets = await mutate({
          asset,
          input,
          allIdentities: allIds,
          selectedIdentity,
          selectedDelegate,
        })
        if (!modifiedAssets) {
          setError(`Couldn't perform transfer ownership action`)
          return
        }
        dispatch(assetsCreatedOrModified(modifiedAssets))
        formik.resetForm()
        return modifiedAssets
      } catch (e: any) {
        setError(e.message)
      }
    },
    [dispatch, mutate, setError, asset, allIds, selectedIdentity, selectedDelegate]
  )

  return [result, error, doIt]
}
