import React from 'react'
import JSONPretty from 'react-json-pretty'
import JSONPrettyTheme from 'react-json-pretty/dist/adventure_time'

interface InstructionPrettyProps {
  className?: string
  name: string
  values?: any[]
}

export const InstructionPretty: React.FC<InstructionPrettyProps> = ({ className, name, values }) => {
  return (
    <div>
      <div>{name}</div>
      <JSONPretty themeClassName={className} data={values} theme={JSONPrettyTheme} />
    </div>
  )
}
