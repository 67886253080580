import { AppState } from '../store'
import { call, put, takeEvery } from 'redux-saga/effects'
import { setLoading } from './loading'
import { StoredIdentity } from '../model'
import { client } from '../client/client'

export const RETRIEVE_PROOF = 'RETRIEVE_PROOF'
export const PROOF_RETRIEVED = 'PROOF_RETRIEVED'

export interface BeginRetrieveProofAction {
  type: typeof RETRIEVE_PROOF
  requestId: string
  requestor: StoredIdentity
}

export interface ProofRetrievedAction {
  type: typeof PROOF_RETRIEVED
  proof: string
}

export type ProofAction = BeginRetrieveProofAction | ProofRetrievedAction

export function retrieveProof(requestId: string, requestor: StoredIdentity): BeginRetrieveProofAction {
  return {
    type: RETRIEVE_PROOF,
    requestId,
    requestor,
  }
}

export function proofRetrieved(proof: string): ProofRetrievedAction {
  return {
    type: PROOF_RETRIEVED,
    proof,
  }
}

export default function proofReducer(state = '', action: ProofAction): string {
  switch (action.type) {
    case PROOF_RETRIEVED:
      return action.proof
    default:
      return state
  }
}

export const getCurrentProof = (state: AppState) => state.proof

function* retrieveProofWorker(action: BeginRetrieveProofAction) {
  yield put(setLoading(true))

  try {
    const proofResponse: Record<string, unknown> = yield call(() =>
      client.getProof(action.requestId, action.requestor)
    )
    yield put(proofRetrieved(JSON.stringify(proofResponse, null, 2)))
  } catch (error: any) {
    if (error.event === 'EXTENSION_LOCKED') {
      alert(error.message)
    }
  }
  yield put(setLoading(false))
}

export function* retrieveProofSaga() {
  yield takeEvery(RETRIEVE_PROOF, retrieveProofWorker)
}
