import { select, takeEvery } from 'redux-saga/effects'
import { StoredDelegation } from '../model'
import LocalStorage from '../LocalStorage'
import { AppState } from '../store'

export const DELEGATE_IDENTITY_ASSIGNED = 'DELEGATE_IDENTITY_ASSIGNED'

export interface DelegateIdentityAssigned {
  type: typeof DELEGATE_IDENTITY_ASSIGNED
  delegate: StoredDelegation
}

export type DelegateIdentityAction = DelegateIdentityAssigned

export function delegateIdentityAssigned(delegate: StoredDelegation): DelegateIdentityAction {
  return {
    type: DELEGATE_IDENTITY_ASSIGNED,
    delegate,
  }
}

const initialState: StoredDelegation[] = JSON.parse(localStorage.getItem(LocalStorage.Delegates) || '[]')

export default function delegateIdentitiesReducer(
  state = initialState,
  action: DelegateIdentityAction
): StoredDelegation[] {
  switch (action.type) {
    case DELEGATE_IDENTITY_ASSIGNED:
      return [...state, action.delegate]
    default:
      return state
  }
}

export const getDelegates = (state: AppState) => state.delegates

function* delegateIdentityAssignedWorker() {
  const state: StoredDelegation[] = yield select(getDelegates)
  localStorage.setItem(LocalStorage.Delegates, JSON.stringify(state))
}

export function* delegateIdentityAssignedSaga() {
  yield takeEvery(DELEGATE_IDENTITY_ASSIGNED, delegateIdentityAssignedWorker)
}
