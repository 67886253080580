import { makeStyles } from '@material-ui/styles'
import { List, ListItem, ListItemIcon, ListItemText, Theme } from '@material-ui/core'
import React from 'react'
import JSONPretty from 'react-json-pretty'
import JSONPrettyTheme from 'react-json-pretty/dist/adventure_time'
import { VoteChip } from './VoteChip'
import { InstructionPretty } from './InstructionPretty'

function proofSummary(proof: Proof): string {
  const authorisation = proof.proof.nodes.find(n => n.id._type === 'Authorisation')
  if (!authorisation) throw new Error(`Can't find "Authorisation" node in proof`)

  return authorisation.payload || ''
}

type ProofNodeType =
  | 'Completion'
  | 'CompletionDetails'
  | 'Vote'
  | 'InstructionMessage'
  | 'Authentication'
  | 'Authorisation'
  | 'Root'

interface ProofNodeId {
  _type: ProofNodeType
  id?: string
}

type Protocol = 'SHA256WithECDSA' | 'SHA256WithRsa'

interface Seal {
  signature: string
  protocolId: Protocol
  identityId: string
  delegateIdentityId?: string | null
}

interface Signatory {
  identity: string
  credentials: {
    key: string
  }
}

interface ProofLink {
  target: ProofNodeId
  seals: Seal[]
}

export interface Fingerprint {
  requestId: string
  seal: Seal
}

interface ProofNode {
  id: ProofNodeId
  payload?: string
  links: ProofLink[]
}

export interface Proof {
  requestId: string
  proof: {
    nodes: ProofNode[]
  }
  signatories: Signatory[]
  parentFingerprints: Fingerprint[]
}

const useDetailsStyles = makeStyles((theme: Theme) => ({
  item: {
    alignItems: 'flex-start',
  },
  icon: {
    marginTop: theme.spacing(),
    flexDirection: 'column',
  },
  text: {
    marginLeft: theme.spacing(),
  },
  list: {
    paddingTop: 0,
  },
  textSecondary: {
    overflow: 'auto',
    maxWidth: 'calc(85vw - 255px)',
  },
  jsonPretty: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export interface Instruction {
  name: string
  values: any
}

export interface Expanded {
  votes: ProofNode[]
  name: string
  instructions: Instruction[]
}

const divComponent: { component: 'div' } = { component: 'div' }

export interface ProofDetailsProps {
  proof: Proof
}

export const ProofDetails: React.FC<ProofDetailsProps> = ({ proof }) => {
  const classes = useDetailsStyles()
  const instructionBatches = proof.proof.nodes.filter(n => n.id._type === 'InstructionMessage')
  const votes = proof.proof.nodes.filter(n => n.id._type === 'Vote')

  const expanded: { [name: string]: Expanded } = instructionBatches.reduce(
    (acc: { [name: string]: Expanded }, n: ProofNode) => {
      if (!n.payload) {
        console.warn(`Returning because instruction batch has no payload`, n)
        return acc
      }
      if (!n.id.id) console.warn(`Couldn't find id for instruction batch`, n)

      const votesForInstructionBatch = votes.filter(v => v.links.some(l => l.target.id === n.id.id))
      if (votesForInstructionBatch.length === 0) console.warn(`Couldn't find votes for instruction batch`, n)

      const instructions: any[] = JSON.parse(n.payload).instructions
      const fullInstructions: Instruction[] = instructions.map(i => {
        const instructionName: string = Object.keys(i)[0]
        return {
          name: instructionName,
          values: i[instructionName],
        }
      })

      return {
        ...acc,
        [n.id.id || '']: {
          instructions: fullInstructions,
          name: 'Batch: ' + n.id.id,
          votes: votesForInstructionBatch,
        },
      }
    },
    {}
  )

  return (
    <List className={classes.list}>
      <ListItem>
        <ListItemText
          classes={{ secondary: classes.textSecondary }}
          secondary={
            <JSONPretty
              themeClassName={classes.jsonPretty}
              data={proofSummary(proof)}
              theme={JSONPrettyTheme}
            />
          }
          secondaryTypographyProps={divComponent}
        />
      </ListItem>
      {Object.keys(expanded).map(k => (
        <ListItem key={k} className={classes.item}>
          <ListItemIcon className={classes.icon}>
            {expanded[k].votes.map(v => (
              <VoteChip key={v.id?.id} payload={v.payload} />
            ))}
          </ListItemIcon>
          <ListItemText
            classes={{ root: classes.text, secondary: classes.textSecondary }}
            primary={expanded[k].name}
            secondary={
              <div>
                {expanded[k].instructions.map(i => (
                  <InstructionPretty className={classes.jsonPretty} name={i.name} values={i.values} />
                ))}
              </div>
            }
            secondaryTypographyProps={divComponent}
          />
        </ListItem>
      ))}
    </List>
  )
}
