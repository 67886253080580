import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Form, Formik, FormikValues } from 'formik'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Identity, SHA256WithECDSA, SHA256WithRSA, StoredIdentity } from 'model'
import { identityCreated } from '../modules/identities'
import { Input, useCreate } from '../utils/useCreate'
import DisplayError from './DisplayError'
import { client } from '../client/client'
import { CREATE_IDENTITY_GUIDE } from '../client/guide'
import { UserGuide } from './UserGuide'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'

export interface ImportDialogProps {
  open: boolean
  onClose: (value: string) => void
}

function ImportDialog(props: ImportDialogProps) {
  const { onClose, open } = props
  const dispatch = useDispatch()
  const formRef = useRef<FormikValues>() as any

  const handleClose = () => {
    onClose('Close Dialog')
  }
  const submit = async (values: any) => {
    if (values.identity !== '') {
      try {
        const importIdentity = JSON.parse(values.identity)
        const id: StoredIdentity = {
          publicKey: importIdentity.keyPair.pubKeyBase64,
          privateKey: importIdentity.keyPair.prvKeyBase64,
          proof: '',
          requestId: '',
          address: importIdentity.keyPair.identityId,
          protocol: importIdentity.keyPair.protocolId,
          present: true,
        }
        const identity = await client.getIdentity(id)
        id.proof = identity.proof
        id.requestId = id.proof.split('/').pop() as string
        dispatch(identityCreated(id))
      } catch (error: any) {
        alert(error.message)
      }
    }
    onClose('Close Dialog')
  }
  function handleChange(event: any) {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        formRef.current.values.identity = reader.result
      }
    }
    reader.readAsText(event.target.files[0])
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Import an identity</DialogTitle>
      <Formik initialValues={{ identity: '' }} onSubmit={submit} innerRef={formRef}>
        <Form>
          <FormControl style={{ display: 'block', padding: 10 }}>
            <Field as="input" name="file" type="file" label="file" onChange={handleChange} />
          </FormControl>
          <div style={{ padding: 10 }}>
            <Button variant="contained" color="primary" type="submit">
              {'Import'}
            </Button>
          </div>
        </Form>
      </Formik>
    </Dialog>
  )
}

export interface ContentProps {
  identity?: Identity
}

async function createIdentity({ input }: Input<Identity>): Promise<StoredIdentity> {
  const response: any = await client.createIdentity(input.protocol, input.address)
  return {
    publicKey: response.pubKeyBase64 as string,
    privateKey: response.prvKeyBase64 as string,
    proof: response.proof as string,
    requestId: response.requestId as string,
    ...input,
    present: true,
  }
}

const CreateIdentity: React.FC<ContentProps> = props => {
  const [result, error, submit] = useCreate(createIdentity, identityCreated)
  const identity = props.identity || {
    protocol: SHA256WithRSA,
    address: '',
  }
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value: string) => {
    setOpen(false)
  }

  return (
    <div>
      <UserGuide id={CREATE_IDENTITY_GUIDE} />
      <Formik initialValues={identity} onSubmit={submit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="address">
              <FormControl style={{ display: 'block' }}>
                <Field as={TextField} name="address" type="string" label="Address" />
              </FormControl>
            </div>
            <br />
            <div className="protocol">
              <FormControl style={{ display: 'block' }}>
                <InputLabel>Protocol</InputLabel>
                <Field as={Select} name="protocol" type="string">
                  <MenuItem value={SHA256WithRSA}>{SHA256WithRSA}</MenuItem>
                  <MenuItem value={SHA256WithECDSA}>{SHA256WithECDSA}</MenuItem>
                </Field>
              </FormControl>
            </div>
            <div style={{ paddingTop: 15 }}>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} /> : 'Create'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: '10px' }}
                onClick={handleClickOpen}
              >
                {'Import'}
              </Button>
              <ImportDialog open={open} onClose={handleClose} />
            </div>
            <DisplayError errorTitle={error} error={result.error} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateIdentity
