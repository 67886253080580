import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from 'sagas'
import selectedIdentity from 'modules/selectedIdentity'
import loadingReducer from 'modules/loading'
import selectedDelegate from 'modules/selectedDelegate'
import proof from 'modules/proof'
import endorsements from 'modules/endorsements'
import identities from 'modules/identities'
import assetTypes from 'modules/assetTypes'
import assets from 'modules/assets'
import claims from 'modules/claims'
import delegateIdentities from 'modules/delegateIdentities'
import carPurchase from 'modules/carPurchase'
import userPreference from 'modules/userPreference'

const rootReducer = (history: History<any>) => {
  return combineReducers({
    assets,
    assetTypes,
    carPurchase,
    claims,
    delegates: delegateIdentities,
    endorsements,
    identities,
    isLoading: loadingReducer,
    proof,
    router: connectRouter(history),
    selectedDelegate,
    selectedIdentity,
    userPreference,
  })
}

export type AppState = ReturnType<ReturnType<typeof rootReducer>>

export const history = createBrowserHistory()

export const sagaMiddleware = createSagaMiddleware()

export function configureStore(preloadedState?: AppState) {
  const middlewares = [sagaMiddleware, routerMiddleware(history)]
  const middleWareEnhancer = applyMiddleware(...middlewares)

  const store = createStore(rootReducer(history), preloadedState, composeWithDevTools(middleWareEnhancer))

  sagaMiddleware.run(rootSaga)

  return store
}
