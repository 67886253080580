import { StoredCarPurchase, StoredEndorsement, StoredIdentity } from '../model'
import LocalStorage from '../LocalStorage'
import { AppState } from '../store'
import { IDENTITY_CREATED } from './identities'
import { ASSET_TYPE_CREATED } from './assetTypes'
import { ASSETS_CREATED_OR_MODIFIED } from './assets'
import { CLAIM_CREATED } from './claims'
import { ENDORSEMENT_CREATED } from './endorsements'

export const CAR_PURCHASE = 'CAR_PURCHASE'

const mvaIdentityId = 'mva-' + Math.random().toString(36).substr(2, 5)
const aliceIdentityId = 'alice-' + Math.random().toString(36).substr(2, 5)
const bobIdentityId = 'bob-' + Math.random().toString(36).substr(2, 5)
const carAssetTypeId = 'carassettype-' + Math.random().toString(36).substr(2, 5)
const carAssetId = 'carasset-' + Math.random().toString(36).substr(2, 5)
const carClaim = 'first-registration=10/02/2010'

const newCarPurchase: StoredCarPurchase = {
  steps: [
    {
      id: 'step1',
      action: 'create-identity',
      name: 'Create an Identity for Motor Vehicle Authority (MVA)',
      text: `Type the address "<b>${mvaIdentityId}</b>" when creating the identity for the MVA. You can choose the protocolId to use.`,
      value: mvaIdentityId,
      executor: mvaIdentityId,
      reached: false,
    },
    {
      id: 'step2',
      action: 'create-asset-type',
      name: 'Mva creates an "Unique" AssetType to represent a Car',
      text: `Select the identity "<b>${mvaIdentityId}</b>", and then type the address "<b>${carAssetTypeId}</b>" when creating the asset type. Make sure you choose type "<b>Unique</b>"`,
      value: carAssetTypeId + ':Unique',
      executor: mvaIdentityId,
      reached: false,
    },
    {
      id: 'step3',
      action: 'create-identity',
      name: 'Create an Identity for Alice',
      text: `Type the address "<b>${aliceIdentityId}</b>" when creating the identity for Alice. You can choose the protocolId to use.`,
      value: aliceIdentityId,
      executor: aliceIdentityId,
      reached: false,
    },
    {
      id: 'step4',
      action: 'create-identity',
      name: 'Create an Identity for Bob',
      text: `Type the address "<b>${bobIdentityId}</b>" when creating the identity for Bob. You can choose the protocolId to use.`,
      value: bobIdentityId,
      executor: bobIdentityId,
      reached: false,
    },
    {
      id: 'step5',
      action: 'create-modify-asset',
      name: 'Alice creates an instance of a car AssetType',
      text: `Select the identity "<b>${aliceIdentityId}</b>", and type the address "<b>${carAssetId}</b>" when creating the asset. Make sure you choose asset type "<b>${carAssetTypeId}</b>"`,
      value: carAssetId + ':' + carAssetTypeId,
      executor: aliceIdentityId,
      reached: false,
    },
    {
      id: 'step6',
      action: 'create-claim',
      name: 'Alice claims the first registration of the car happened in 2010',
      text: `Make sure the identity "<b>${aliceIdentityId}</b>" is selected, then add the claim "<b>${carClaim}</b>" to the asset "<b>${carAssetId}</b>"`,
      value: carClaim + ':' + carAssetTypeId + ':' + carAssetId,
      executor: aliceIdentityId,
      reached: false,
    },
    {
      id: 'step7',
      action: 'add-endorsement',
      name: "MVA endorses Alice's claim of the registration date",
      text: `Select the identity "<b>${mvaIdentityId}</b>", select the claim "<b>${carAssetTypeId}:${carAssetId} - ${carClaim}</b>" and click the endorse button`,
      value: carClaim + ':' + carAssetTypeId + ':' + carAssetId,
      executor: mvaIdentityId,
      reached: false,
    },
    {
      id: 'step8',
      action: 'check-endorsement',
      name: 'Bob checks that the registration claim from Alice is in fact endorsed by the MVA',
      text: `Select the identity "<b>${bobIdentityId}</b>", then retrieve the list of endorsements`,
      value: carClaim + ':' + carAssetTypeId + ':' + carAssetId,
      executor: bobIdentityId,
      reached: false,
    },
    {
      id: 'step9',
      action: 'create-modify-asset',
      name: 'Alice transfers the car instance to Bob',
      text: `Select the identity "<b>${aliceIdentityId}</b>", select asset "<b>${carAssetId}</b>" and then choose "<b>${bobIdentityId}</b>" in the "<b>To identity</b>" field`,
      value: carAssetId + ':' + carAssetTypeId,
      executor: bobIdentityId,
      reached: false,
    },
  ],
}

export interface CarPurchaseAction {
  type: typeof CAR_PURCHASE
  carPurchase: StoredCarPurchase
}

export function selectCarPurchase(carPurchase: StoredCarPurchase | null) {
  return {
    type: CAR_PURCHASE,
    carPurchase,
  }
}

const initialState: StoredCarPurchase | null = JSON.parse(
  localStorage.getItem(LocalStorage.CarPurchase) || 'null'
)

export function carPurchaseUpdate(
  action: string,
  value: string,
  executor: string,
  requestId: string
): StoredCarPurchase {
  const carPurchase: StoredCarPurchase = JSON.parse(localStorage.getItem(LocalStorage.CarPurchase) as string)
  if (carPurchase) {
    for (const step of carPurchase.steps) {
      if (step.action === action && step.value === value && step.executor === executor) {
        step.reached = true
        step.requestId = requestId
        localStorage.setItem(LocalStorage.CarPurchase, JSON.stringify(carPurchase))
        break
      }
    }
  }
  return carPurchase
}

export default function carPurchaseReducer(state = initialState, action: any): StoredCarPurchase | null {
  switch (action.type) {
    case IDENTITY_CREATED:
      return carPurchaseUpdate(
        'create-identity',
        action.identity.address,
        action.identity.address,
        action.identity.requestId
      )
    case ASSET_TYPE_CREATED:
      return carPurchaseUpdate(
        'create-asset-type',
        action.assetType.address + ':' + action.assetType.type,
        action.assetType.owner,
        action.assetType.requestId
      )
    case ASSETS_CREATED_OR_MODIFIED:
      return carPurchaseUpdate(
        'create-modify-asset',
        action.assets[0].address + ':' + action.assets[0].assetTypeId,
        action.assets[0].owner,
        action.assets[0].requestId
      )
    case CLAIM_CREATED:
      return carPurchaseUpdate(
        'create-claim',
        action.claim.plaintextClaim + ':' + action.claim.subjectId.replace(/\s/g, ''),
        action.claim.claimant.authenticator.address,
        action.claim.requestId
      )
    case ENDORSEMENT_CREATED:
      return carPurchaseUpdate(
        'add-endorsement',
        action.endorsement.claim.plaintextClaim + ':' + action.endorsement.claim.subjectId.replace(/\s/g, ''),
        action.endorsement.endorser.authenticator.address,
        action.endorsement.requestId
      )

    case '@@router/LOCATION_CHANGE':
      if (action.payload.location.pathname === '/endorsements/list') {
        const endorsements: StoredEndorsement[] = JSON.parse(
          localStorage.getItem(LocalStorage.Endorsements) || '[]'
        )
        const selectedIdentity: StoredIdentity = JSON.parse(
          localStorage.getItem(LocalStorage.SelectedIdentity) as string
        )
        for (const endorsement of endorsements) {
          state = carPurchaseUpdate(
            'check-endorsement',
            endorsement.claim.plaintextClaim + ':' + endorsement.claim.subjectId.replace(/\s/g, ''),
            selectedIdentity.address,
            endorsement.requestId
          )
        }
      }
      return state

    case CAR_PURCHASE:
      localStorage.setItem(LocalStorage.CarPurchase, JSON.stringify(action.carPurchase))
      return action.carPurchase
    default:
      return state
  }
}

export const getCarPurchase = (state: AppState) => {
  if (state.carPurchase === null) {
    localStorage.setItem(LocalStorage.CarPurchase, JSON.stringify(newCarPurchase))
    state.carPurchase = newCarPurchase
  } else {
    state.carPurchase = JSON.parse(localStorage.getItem(LocalStorage.CarPurchase) as string)
  }
  return state.carPurchase as StoredCarPurchase
}
