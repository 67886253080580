import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { retrieveProof, getCurrentProof } from 'modules/proof'
import { getSelectedIdentity } from 'modules/selectedIdentity'
import { VisualiseProofJson } from './VisualiseProofJson'

export interface VisualiseProofProps {}

export const VisualiseProof: React.FC<VisualiseProofProps> = () => {
  const { id } = useParams() as { id: string }
  const proof = useSelector(getCurrentProof)
  const selectedIdentity = useSelector(getSelectedIdentity)
  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedIdentity && id) {
      dispatch(retrieveProof(id, selectedIdentity))
    }
  }, [id, dispatch, selectedIdentity])

  const proofJson = proof ? JSON.parse(proof) : undefined

  return proofJson ? <VisualiseProofJson proof={proofJson} /> : null
}
