import { AppState } from '../store'

export const SET_LOADING = 'SET_LOADING'

export interface SetLoadingAction {
  type: typeof SET_LOADING
  isLoading: boolean
}

export function setLoading(isLoading: boolean): SetLoadingAction {
  return {
    type: SET_LOADING,
    isLoading,
  }
}

const initialState = false

export default function setLoadingReducer(state = initialState, action: SetLoadingAction): boolean {
  switch (action.type) {
    case SET_LOADING:
      return action.isLoading
    default:
      return state
  }
}

export const isLoading = (state: AppState) => state.isLoading
