import React from 'react'
import { Avatar, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { noop } from '../noop'

export interface ItemWithProofProps {
  onClick?: () => void
  title: string
  preDetail?: JSX.Element
  buttons?: JSX.Element
  avatarIcon: JSX.Element
}

export const ItemWithoutProof: React.FC<ItemWithProofProps> = ({
  onClick,
  title,
  preDetail,
  buttons,
  avatarIcon,
}) => {
  return (
    <ListItem button onClick={onClick || noop}>
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: 'secondary' }}>{avatarIcon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <>
            {preDetail ? (
              <>
                {preDetail}
                <br />
              </>
            ) : (
              ''
            )}
          </>
        }
      />
      <ListItemSecondaryAction>{buttons}</ListItemSecondaryAction>
    </ListItem>
  )
}
