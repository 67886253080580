import React, { useEffect } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import { Route, Switch } from 'react-router'
import CssBaseline from '@material-ui/core/CssBaseline'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Navigator from './Navigator'
import Header from './Header'
import theme from 'theme'
import Home from './Home'
import ViewIdentity from './ViewIdentity'
import ListIdentities from './ListIdentities'
import ListAssetTypes from './ListAssetTypes'
import Authentication from './Authentication'
import { ConnectedRouter, replace } from 'connected-react-router'
import { history } from 'store'
import Locations from 'Locations'
import { useDispatch, useSelector } from 'react-redux'
import { Backdrop, CircularProgress } from '@material-ui/core'
import ViewClaim from './ViewClaim'
import ListClaims from './ListClaims'
import ListDelegateIdentities from './ListDelegateIdentities'
import ViewRequest from './ViewRequest'
import ListEndorsements from './ListEndorsements'
import { isLoading } from 'modules/loading'
import ViewAsset from './ViewAsset'
import { isAuthenticated } from '../modules/selectedIdentity'
import { getAssetTypes } from 'modules/assetTypes'
import { VisualiseProof } from './visualise-proof/VisualiseProof'
import ListAssets from './ListAssets'
import CarPurchase from './CarPurchase'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://iov42.com/">
        IOV42 Limited
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const drawerWidth = 256

const useStyles = makeStyles({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
})

export interface AppProps {}

function ifAuthenticated<A>(Component: React.ComponentType<A>): (props: A) => JSX.Element | null {
  return props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const authenticated = useSelector(isAuthenticated)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch()
    useEffect(() => {
      if (!authenticated) {
        dispatch(replace(Locations.ListIdentities.uri))
      }
    }, [authenticated, dispatch])
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Component {...props} />
  }
}

function ifAuthenticatedAndAssetTypesAvailable<A>(
  Component: React.ComponentType<A>
): (props: A) => JSX.Element | null {
  return props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const assetTypes = useSelector(getAssetTypes)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch()
    const assetTypesSize = assetTypes.length
    useEffect(() => {
      if (assetTypesSize === 0) {
        dispatch(replace(Locations.ListAssetTypes.uri))
      }
    }, [assetTypesSize, dispatch])
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Component {...props} />
  }
}

const Protected = {
  Home,
  ListAssetTypes: ifAuthenticated(ListAssetTypes),
  ListAssets: ifAuthenticatedAndAssetTypesAvailable(ListAssets),
  ViewAsset: ifAuthenticatedAndAssetTypesAvailable(ViewAsset),
  ViewIdentity: ifAuthenticated(ViewIdentity),
  ViewRequest: ifAuthenticated(ViewRequest),
  VisualiseProof: ifAuthenticated(VisualiseProof),
  ListIdentities,
  ListDelegateIdentities: ifAuthenticated(ListDelegateIdentities),
  Authentication,
  ListClaims: ifAuthenticated(ListClaims),
  ViewClaim: ifAuthenticated(ViewClaim),
  ListEndorsements: ifAuthenticated(ListEndorsements),
  CarPurchase,
}

const Routes = () => (
  <Switch>
    <Route exact path={Locations.Home.uri} component={Protected.Home} />
    <Route exact path={Locations.ListAssetTypes.uri} component={Protected.ListAssetTypes} />
    <Route exact path={Locations.ListAssets.uri} component={Protected.ListAssets} />
    <Route exact path={Locations.ViewAsset.uri} component={Protected.ViewAsset} />
    <Route exact path={Locations.ViewIdentity.uri} component={Protected.ViewIdentity} />
    <Route exact path={Locations.ViewRequest.uri} component={Protected.ViewRequest} />
    <Route exact path={Locations.VisualiseProof.uri} component={Protected.VisualiseProof} />
    <Route exact path={Locations.ListIdentities.uri} component={Protected.ListIdentities} />
    <Route exact path={Locations.ListDelegateIdentities.uri} component={Protected.ListDelegateIdentities} />
    <Route exact path={Locations.Authentication.uri} component={Protected.Authentication} />
    <Route exact path={Locations.ListClaims.uri} component={Protected.ListClaims} />
    <Route exact path={Locations.ViewClaim.uri} component={Protected.ViewClaim} />
    <Route exact path={Locations.ListEndorsements.uri} component={Protected.ListEndorsements} />
    <Route exact path={Locations.CarPurchase.uri} component={Protected.CarPurchase} />
  </Switch>
)

const App: React.FC<AppProps> = () => {
  const classes = useStyles()
  const loading = useSelector(isLoading)
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
          <div className={classes.app}>
            <Header />
            <main className={classes.main}>
              <Routes />
            </main>
            <footer className={classes.footer}>
              <Copyright />
            </footer>
          </div>
        </div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </ConnectedRouter>
    </ThemeProvider>
  )
}

export default App
