import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router'
import { getAssets, quantityString } from '../modules/assets'
import { getAssetTypes } from '../modules/assetTypes'
import { useSelector } from 'react-redux'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Link from '@material-ui/core/Link'
import Locations from '../Locations'
import { QUANTIFIABLE, QuantifiableAssetType, Account } from '../model'
import AddQuantity from './AddQuantity'
import TransferQuantity from './TransferQuantity'
import TransferOwnership from './TransferOwnership'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

export interface ContentProps {}

const ViewAsset: React.FC<ContentProps> = _ => {
  const { id } = useParams() as {
    id: string
  }
  const assets = useSelector(getAssets)
  const assetTypes = useSelector(getAssetTypes)
  const asset = assets.find(a => a.address === id)
  const assetType = assetTypes.find(at => at.address === asset?.assetTypeId)
  const dispatch = useDispatch()

  if (asset === undefined || assetType === undefined) {
    return (
      <Typography color="textSecondary" align="center">
        Asset {id} or its type not found.
      </Typography>
    )
  }

  const isAccount = assetType.type === QUANTIFIABLE

  return (
    <>
      {isAccount ? (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <AddQuantity asset={asset} />
                </TableCell>
                <TableCell>
                  <TransferQuantity fromAsset={asset} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TransferOwnership asset={asset} />
      )}
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>{isAccount ? 'Account' : 'Unique asset'}</b>
              </TableCell>
              <TableCell>
                {asset.address}
                {isAccount ? <br /> : ''}
                {isAccount ? quantityString(asset as Account, assetType as QuantifiableAssetType) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Asset Type</b>
              </TableCell>
              <TableCell>
                {assetType.address}
                {isAccount ? <br /> : ''}
                {isAccount ? `Scale: ${(assetType as QuantifiableAssetType).scale}` : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Last request ID</b>
              </TableCell>
              <TableCell>
                <Link onClick={() => dispatch(push(Locations.ViewRequest.buildUri({ id: asset.requestId })))}>
                  {asset.requestId}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Owned by</b>
              </TableCell>
              <TableCell>
                {asset.delegateIdentityId
                  ? `${asset.delegateIdentityId} on behalf of ${asset.owner}`
                  : asset.owner}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ViewAsset
