import { select, takeEvery } from 'redux-saga/effects'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { Account, QuantifiableAssetType, StoredAsset } from '../model'
import LocalStorage from '../LocalStorage'
import { AppState } from '../store'
import { createSelector } from 'reselect'
import { addOrReplace } from '../utils/arrayAndStringsUtils'

export const ASSETS_CREATED_OR_MODIFIED = 'ASSETS_CREATED_OR_MODIFIED'

interface AssetsCreatedOrModified {
  type: typeof ASSETS_CREATED_OR_MODIFIED
  assets: StoredAsset[]
}

export type AssetAction = AssetsCreatedOrModified

export function assetsCreatedOrModified(assets: StoredAsset[]): AssetAction {
  return {
    type: ASSETS_CREATED_OR_MODIFIED,
    assets,
  }
}

export interface AssetState {
  assets: StoredAsset[]
  isCreating: boolean
  error?: Error
}

const initialState: AssetState = {
  isCreating: false,
  assets: JSON.parse(localStorage.getItem(LocalStorage.Assets) || '[]'),
}

export default function assetReducer(
  state = initialState,
  action: AssetAction | LocationChangeAction
): AssetState {
  switch (action.type) {
    case ASSETS_CREATED_OR_MODIFIED:
      return {
        ...state,
        isCreating: false,
        assets: addOrReplace(state.assets, action.assets, a => a.assetTypeId + ':' + a.address),
      }
    case LOCATION_CHANGE:
      if (state.error !== undefined) {
        return {
          ...state,
          error: undefined,
        }
      }
      return state
    default:
      return state
  }
}

export function quantityString(asset: Account, assetType: QuantifiableAssetType) {
  return `Quantity: ${asset.quantity} - Actual quantity: ${actualQuantityString(
    asset.quantity,
    assetType.scale
  )}`
}

function actualQuantityString(quantity: number, scale: number) {
  const actualQuantity = quantity / 10 ** scale
  return actualQuantity.toFixed(scale)
}

const getState = (state: AppState) => state.assets
export const getAssets = createSelector([getState], a => a.assets)

function* assetCreatedWorker() {
  const assets: AssetState = yield select(getAssets)
  localStorage.setItem(LocalStorage.Assets, JSON.stringify(assets))
}

export function* assetCreatedSaga() {
  yield takeEvery(ASSETS_CREATED_OR_MODIFIED, assetCreatedWorker)
}
