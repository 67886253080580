const LocalStorage = {
  Assets: 'assets',
  AssetTypes: 'asset-types',
  Claims: 'claims',
  Delegates: 'delegates',
  Endorsements: 'endorsements',
  Identities: 'identities',
  SelectedDelegateIdentity: 'delegate-identity',
  SelectedIdentity: 'selected-identity',
  CarPurchase: 'car-purchase',
  UserPreference: 'user-preference',
}

export default LocalStorage
