import React from 'react'
import { Field, Form, Formik } from 'formik'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getIdentities } from 'modules/identities'
import { ASSET_CLAIM_SEPARATOR, claimCreated } from 'modules/claims'
import { StoredClaim } from '../model'
import { Input, useCreate } from '../utils/useCreate'
import { sleep } from '../utils/sleep'
import { getAssetTypes } from '../modules/assetTypes'
import { getAssets } from '../modules/assets'
import { AppState } from '../store'
import { capitaliseFirst } from '../utils/arrayAndStringsUtils'
import DisplayError from './DisplayError'
import { client } from '../client/client'
import { clientLib } from '../client/clientLib'
import { StoredIdentity } from 'model'
import { CREATE_CLAIM_GUIDE } from '../client/guide'
import { UserGuide } from './UserGuide'

interface ClaimChoice {
  subjectId: string
  subjectType: SubjectType
  plaintextClaim: string
}

export const IDENTITIES_SUBJECT = 'identities'
export const ASSET_TYPES_SUBJECT = 'asset-types'
export const ASSETS_SUBJECT = 'assets'
export type SubjectType = typeof IDENTITIES_SUBJECT | typeof ASSET_TYPES_SUBJECT | typeof ASSETS_SUBJECT

async function createIdentityClaim(
  input: any,
  selectedIdentity: StoredIdentity,
  selectedDelegate: StoredIdentity
) {
  const createResponse = await client.createIdentityClaims(input, selectedIdentity, selectedDelegate)
  await sleep(1000)
  const claimResponse = await client.getIdentityClaim(
    input.subjectId,
    clientLib.platformUtils.hash('sha256', input.plaintextClaim),
    selectedIdentity,
    selectedDelegate
  )
  return { createResponse, claimResponse }
}

async function createAssetTypeClaim(
  input: any,
  selectedIdentity: StoredIdentity,
  selectedDelegate: StoredIdentity
) {
  const createResponse = await client.createAssetTypeClaims(input, selectedIdentity, selectedDelegate)
  await sleep(1000)
  const claimResponse = await client.getAssetTypeClaim(
    input.subjectId,
    clientLib.platformUtils.hash('sha256', input.plaintextClaim),
    selectedIdentity,
    selectedDelegate
  )
  return { createResponse, claimResponse }
}

async function createAssetClaim(
  input: any,
  selectedIdentity: StoredIdentity,
  selectedDelegate: StoredIdentity
) {
  const parts = input.subjectId.split(ASSET_CLAIM_SEPARATOR)
  const newInput = {
    plaintextClaim: input.plaintextClaim,
    subjectId: parts[1],
    subjectTypeId: parts[0],
  }
  const createResponse = await client.createAssetClaims(newInput, selectedIdentity, selectedDelegate)
  await sleep(1000)
  const claimResponse = await client.getAssetClaim(
    newInput.subjectId,
    newInput.subjectTypeId,
    clientLib.platformUtils.hash('sha256', input.plaintextClaim),
    selectedIdentity,
    selectedDelegate
  )
  return { createResponse, claimResponse }
}

async function createClaim({
  input,
  selectedIdentity,
  selectedDelegate,
}: Input<ClaimChoice>): Promise<StoredClaim> {
  if (selectedIdentity === null) {
    throw new Error('No identity selected')
  }
  const identity = selectedDelegate || selectedIdentity
  if (!identity) {
    throw new Error('Selected identity not found')
  }
  const claimCreators = {
    [IDENTITIES_SUBJECT]: createIdentityClaim,
    [ASSET_TYPES_SUBJECT]: createAssetTypeClaim,
    [ASSETS_SUBJECT]: createAssetClaim,
  }
  const response = await claimCreators[input.subjectType](
    input,
    selectedIdentity,
    selectedDelegate as StoredIdentity
  )

  return {
    subjectId: input.subjectId,
    plaintextClaim: input.plaintextClaim,
    claimant: {
      authenticator: selectedIdentity,
      delegate: selectedDelegate,
    },
    delegateIdentityId: response.claimResponse.delegateIdentityId,
    hashedClaim: response.claimResponse.claim,
    proof: response.claimResponse.proof,
    requestId: response.createResponse.requestId,
    subjectType: input.subjectType,
  }
}

function subjectsSelector(
  subjectType: SubjectType
): (state: AppState) => { address: string; assetTypeId?: string }[] {
  switch (subjectType) {
    case ASSET_TYPES_SUBJECT:
      return getAssetTypes
    case ASSETS_SUBJECT:
      return getAssets
    default:
      return getIdentities
  }
}

export interface CreateClaimProps {
  subjectType: SubjectType
}

const CreateClaim: React.FC<CreateClaimProps> = ({ subjectType }) => {
  const [result, error, submit] = useCreate(createClaim, claimCreated)
  const subjects = useSelector(subjectsSelector(subjectType))

  const claim = {
    subjectId: '',
    subjectType: subjectType,
    plaintextClaim: '',
  }

  return (
    <div>
      <UserGuide id={CREATE_CLAIM_GUIDE} />
      <Formik initialValues={claim} onSubmit={submit} enableReinitialize={true}>
        {({ isSubmitting }) => (
          <Form>
            <div className="subjectid">
              <FormControl style={{ display: 'block', marginBottom: '5px' }}>
                <InputLabel>{capitaliseFirst(subjectType)}</InputLabel>
                <Field as={Select} name="subjectId" type="string" style={{ minWidth: 120 }}>
                  {subjects.map(subject => {
                    const key =
                      'assetTypeId' in subject
                        ? `${subject['assetTypeId']}${ASSET_CLAIM_SEPARATOR}${subject['address']}`
                        : subject.address
                    return (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    )
                  })}
                </Field>
              </FormControl>
            </div>
            <Field type="hidden" name="subjectType" />
            <div className="claim">
              <FormControl style={{ display: 'block', marginBottom: '5px' }}>
                <Field as={TextField} name="plaintextClaim" type="string" label="Claim" />
              </FormControl>
            </div>
            <div style={{ paddingTop: 15 }}>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} /> : 'Create'}
              </Button>
            </div>
            <DisplayError errorTitle={error} error={result.error} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateClaim
