import { MutationResult, useMutation } from 'react-query'
import { FormikHelpers } from 'formik'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedIdentity } from '../modules/selectedIdentity'
import { getSelectedDelegate } from '../modules/selectedDelegate'
import { StoredIdentity } from '../model'
import { getIdentities } from 'modules/identities'

export interface Input<A> {
  input: A
  selectedIdentity: StoredIdentity | null
  selectedDelegate: StoredIdentity | null
  identities: StoredIdentity[]
}

export function useCreate<A, R>(
  create: (a: Input<A>) => Promise<R>,
  created: (r: R) => void
): [MutationResult<R, Error>, string, (a: A, formik: FormikHelpers<A>) => Promise<R | undefined>] {
  const [mutate, result] = useMutation(create)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const selectedIdentity = useSelector(getSelectedIdentity)
  const selectedDelegate = useSelector(getSelectedDelegate)
  const identities = useSelector(getIdentities)

  const submit = useCallback(
    async (a: A, formik: FormikHelpers<A>) => {
      setError('')
      try {
        const newA = await mutate({ input: a, selectedIdentity, selectedDelegate, identities })
        if (!newA) {
          setError(`Couldn't perform create action`)
          return
        }
        dispatch(created(newA))
        formik.resetForm()
        return newA
      } catch (e: any) {
        setError(e.message)
      }
    },
    [created, dispatch, mutate, setError, selectedIdentity, selectedDelegate, identities]
  )

  return [result, error, submit]
}
