import React from 'react'
import { Field, Form, Formik } from 'formik'
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoredAsset } from '../model'
import { useSelector } from 'react-redux'
import { useTransferOwnership } from '../utils/useTransferOwnership'
import { getIdentities } from 'modules/identities'
import DisplayError from './DisplayError'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonContainer: {
    paddingTop: '1em',
  },
  error: {
    marginTop: '1em',
    color: theme.palette.error.main,
  },
}))

export interface TransferOwnershipProps {
  asset: StoredAsset
}

const TransferOwnership: React.FC<TransferOwnershipProps> = ({ asset }) => {
  const classes = useStyles()
  const targetIdentities = useSelector(getIdentities).filter(i => i.address !== asset.owner)
  const [result, error, submit] = useTransferOwnership(asset)

  return (
    <Formik initialValues={{ toIdentity: '' }} onSubmit={submit}>
      {({ values, isSubmitting }) => (
        <Form className={classes.form}>
          <FormControl>
            <InputLabel>To identity:</InputLabel>
            <Field as={Select} name="toIdentity" type="string" style={{ minWidth: 120 }}>
              {targetIdentities.map(toIdentity => (
                <MenuItem key={toIdentity.address} value={toIdentity.address}>
                  {toIdentity.address}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting || values.toIdentity === ''}
            >
              {isSubmitting ? <CircularProgress size={24} /> : 'Transfer Ownership'}
            </Button>
          </div>
          <DisplayError errorTitle={error} error={result.error} />
        </Form>
      )}
    </Formik>
  )
}

export default TransferOwnership
