import React from 'react'
import { Field, Form, Formik } from 'formik'
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Delegation, StoredDelegation, StoredIdentity } from 'model'
import { useSelector } from 'react-redux'
import { getIdentities } from 'modules/identities'
import { delegateIdentityAssigned } from 'modules/delegateIdentities'
import { Input, useCreate } from '../utils/useCreate'
import DisplayError from './DisplayError'
import { client } from '../client/client'
import { ADDING_DELEGATE_GUIDE } from '../client/guide'
import { UserGuide } from './UserGuide'

export interface AssignDelegateIdentityProps {
  delegate?: Delegation
}

interface DelegateChoice {
  delegatorAddress: string
  delegateAddress: string
  identities: StoredIdentity[]
}

async function assignDelegate({
  input: { delegatorAddress, delegateAddress, identities },
}: Input<DelegateChoice>): Promise<StoredDelegation> {
  const delegator = identities.find(identity => identity.address === delegatorAddress)
  const delegate = identities.find(identity => identity.address === delegateAddress)
  if (!delegator || !delegate) {
    throw new Error(`Please select both a delegator and a delegate`)
  }
  const response = await client.assignDelegate(delegator, delegate)

  return {
    proof: response.proof,
    requestId: response.requestId,
    delegator,
    delegate,
  }
}

const AssignDelegateIdentity: React.FC<AssignDelegateIdentityProps> = props => {
  const [result, error, submit] = useCreate(assignDelegate, delegateIdentityAssigned)
  const identities = useSelector(getIdentities)

  const delegateChoice: DelegateChoice = {
    delegatorAddress: props.delegate?.delegator?.address || '',
    delegateAddress: props.delegate?.delegate?.address || '',
    identities,
  }

  return (
    <div>
      <UserGuide id={ADDING_DELEGATE_GUIDE} />
      <Formik initialValues={delegateChoice} onSubmit={submit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="delegator">
              <FormControl style={{ display: 'block', marginBottom: '5px' }}>
                <InputLabel>Delegator</InputLabel>
                <Field as={Select} name="delegatorAddress" type="string" style={{ minWidth: 120 }}>
                  {identities.map(identity => (
                    <MenuItem key={identity.address} value={identity.address}>
                      {identity.address}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </div>
            <div className="delegate">
              <FormControl
                placeholder="Delegate"
                style={{ display: 'block', marginBottom: '5px', minWidth: 120 }}
              >
                <InputLabel>Delegate</InputLabel>
                <Field as={Select} name="delegateAddress" type="string" style={{ minWidth: 120 }}>
                  {identities.map(identity => (
                    <MenuItem key={identity.address} value={identity.address}>
                      {identity.address}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </div>
            <div style={{ paddingTop: 15 }}>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} /> : 'Assign delegate'}
              </Button>
            </div>
            <DisplayError errorTitle={error} error={result.error} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AssignDelegateIdentity
