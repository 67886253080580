import { all, fork } from 'redux-saga/effects'
import { assetTypeCreatedSaga } from '../modules/assetTypes'
import { claimCreatedSaga } from 'modules/claims'
import { retrieveProofSaga } from '../modules/proof'
import { endorsementCreatedSaga } from '../modules/endorsements'
import { assetCreatedSaga } from '../modules/assets'
import { selectIdentitySaga } from '../modules/selectedIdentity'
import { identityCreatedSaga, deleteIdentitySaga } from '../modules/identities'
import { delegateIdentityAssignedSaga } from '../modules/delegateIdentities'
import { userPreferenceSaga } from '../modules/userPreference'

export function* rootSaga() {
  yield all([
    fork(identityCreatedSaga),
    fork(deleteIdentitySaga),
    fork(assetTypeCreatedSaga),
    fork(assetCreatedSaga),
    fork(claimCreatedSaga),
    fork(endorsementCreatedSaga),
    fork(retrieveProofSaga),
    fork(selectIdentitySaga),
    fork(delegateIdentityAssignedSaga),
    fork(userPreferenceSaga),
  ])
}
