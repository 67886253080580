import { MutationResult, useMutation } from 'react-query'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedIdentity } from '../modules/selectedIdentity'
import { getSelectedDelegate } from '../modules/selectedDelegate'
import { StoredAccount, StoredAsset, StoredIdentity } from '../model'
import { sleep } from './sleep'
import { FormikHelpers } from 'formik'
import { assetsCreatedOrModified, getAssets } from '../modules/assets'
import { getIdentities } from '../modules/identities'
import { client } from '../client/client'

export interface Input {
  quantity: string
  toAsset: string
}

export interface MutateInput {
  input: Input
  account: StoredAsset
  allAssets: StoredAsset[]
  allIdentities: StoredIdentity[]
  selectedIdentity: StoredIdentity | null
  selectedDelegate: StoredIdentity | null
}

async function transferQuantity(i: MutateInput): Promise<StoredAccount[]> {
  if (i.selectedIdentity === null) {
    throw new Error('No identity selected')
  }
  const addressToFind = i.selectedDelegate ? i.selectedDelegate.address : i.selectedIdentity.address
  const identity = i.allIdentities.find(id => id.address === addressToFind)
  if (!identity) {
    throw new Error('Selected identity not found')
  }
  const transferQuantityResponse = await client.transferQuantity(
    i.input,
    i.account,
    i.selectedIdentity,
    i.selectedDelegate as StoredIdentity
  )
  await sleep(1000)
  const fromAccountResponse = await client.getAsset(
    i.account.address,
    i.account.assetTypeId,
    i.selectedIdentity,
    i.selectedDelegate as StoredIdentity
  )
  const toIdentity = findOwnerOfTargetAccount(i)
  if (!toIdentity) {
    throw new Error('To identity not found')
  }
  const toAccountResponse = await client.getAsset(i.input.toAsset, i.account.assetTypeId, toIdentity)

  return [
    {
      proof: transferQuantityResponse.proof,
      requestId: transferQuantityResponse.requestId,
      owner: fromAccountResponse.ownerId,
      address: fromAccountResponse.assetId,
      assetTypeId: fromAccountResponse.assetTypeId,
      quantity: fromAccountResponse.quantity,
    },
    {
      proof: transferQuantityResponse.proof,
      requestId: transferQuantityResponse.requestId,
      owner: toAccountResponse.ownerId,
      address: toAccountResponse.assetId,
      assetTypeId: toAccountResponse.assetTypeId,
      quantity: toAccountResponse.quantity,
    },
  ]
}

function findOwnerOfTargetAccount(i: MutateInput): StoredIdentity {
  const targetAccount = i.allAssets.find(
    a => a.address === i.input.toAsset && a.assetTypeId === i.account.assetTypeId
  )
  const toAssetOwner = i.allIdentities.find(i => i.address === targetAccount?.owner)
  if (toAssetOwner === undefined) {
    throw new Error('Could not find target account owner identity')
  }
  return toAssetOwner
}

export function useTransferQuantity(
  account: StoredAsset
): [
  MutationResult<StoredAccount[], Error>,
  string,
  (input: Input, formik: FormikHelpers<Input>) => Promise<StoredAccount[] | undefined>
] {
  const [mutate, result] = useMutation(transferQuantity)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const selectedIdentity = useSelector(getSelectedIdentity)
  const selectedDelegate = useSelector(getSelectedDelegate)

  // we need to get all assets and identities to allow the use of target account credentials to retrieve it
  const allAssets = useSelector(getAssets)
  const allIds = useSelector(getIdentities)

  const doIt = useCallback(
    async (input: Input, formik: FormikHelpers<Input>) => {
      setError('')
      try {
        const modifiedAccounts = await mutate({
          account,
          input,
          allAssets,
          allIdentities: allIds,
          selectedIdentity,
          selectedDelegate,
        })
        if (!modifiedAccounts) {
          setError(`Couldn't perform transfer quantity action`)
          return
        }
        dispatch(assetsCreatedOrModified(modifiedAccounts))
        formik.resetForm()
        return modifiedAccounts
      } catch (e: any) {
        setError(e.message)
      }
    },
    [dispatch, mutate, setError, account, allAssets, allIds, selectedIdentity, selectedDelegate]
  )

  return [result, error, doIt]
}
