import { makeStyles } from '@material-ui/styles'
import { Chip, Theme } from '@material-ui/core'
import React from 'react'
import { Cancel, CheckCircle } from '@material-ui/icons'
import { Fingerprint } from './ProofDetails'

const useVoteStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginBottom: theme.spacing(),
  },
}))

//type VoteContainer = YesVote | NoVote

//interface YesVote {
//  Yes: VotePayload
//}

interface NoVote {
  No: VotePayload & {
    reasons: {
      address?: { assetId: string; assetTypeId: string }
      _type: string
    }[]
  }
}

interface VotePayload {
  batchId: string
  requestId: string
  voter: string
  parents: Fingerprint[]
  version: string
}

interface VoteChipProps {
  payload: string | undefined
}

function displayVoteNo(vote: NoVote): string | undefined {
  const reasons = Array.from(new Set(vote.No.reasons.map(r => r._type))).join(', ')
  return `${vote.No.voter} — ${reasons}`
}

export const VoteChip: React.FC<VoteChipProps> = ({ payload }) => {
  const classes = useVoteStyles()
  if (!payload) return null
  const vote: any = JSON.parse(payload)
  const isYes = vote._type === 'Yes'
  const voterString = isYes ? vote.voter : displayVoteNo(vote as NoVote)
  return (
    <Chip
      className={classes.chip}
      size="small"
      icon={isYes ? <CheckCircle /> : <Cancel />}
      label={voterString}
      color={isYes ? 'primary' : 'secondary'}
    />
  )
}
