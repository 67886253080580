import { SubjectType } from './components/CreateClaim'

export const SHA256WithRSA = 'SHA256WithRSA'
export const SHA256WithECDSA = 'SHA256WithECDSA'

export type Protocol = typeof SHA256WithECDSA | typeof SHA256WithRSA

export interface Identity {
  protocol: Protocol
  address: string
}

export type StoredIdentity = Identity & {
  publicKey: string
  privateKey: string
  proof: string
  requestId: string
  present: boolean
}

export type Requestor = {
  authenticator: StoredIdentity
  delegate: StoredIdentity | null
}

export interface Delegation {
  delegator: StoredIdentity
  delegate: StoredIdentity
}

export type StoredDelegation = Delegation & {
  requestId: string
  proof: string
}

export const QUANTIFIABLE = 'Quantifiable'
export const UNIQUE = 'Unique'

export type AssetTypeType = typeof QUANTIFIABLE | typeof UNIQUE

export interface UniqueAssetType {
  type: typeof UNIQUE
  address: string
}

export interface QuantifiableAssetType {
  type: typeof QUANTIFIABLE
  address: string
  scale: number
}

export type AssetType = UniqueAssetType | QuantifiableAssetType

export interface StoredAssetData {
  delegateIdentityId?: string
  proof: string
  requestId: string
  owner: string
}

export type StoredAssetType = AssetType & StoredAssetData

export interface UniqueAsset {
  assetTypeId: string
  address: string
}

export interface Account {
  assetTypeId: string
  address: string
  quantity: number
}

export type Asset = UniqueAsset | Account

export type StoredUniqueAsset = UniqueAsset & StoredAssetData
export type StoredAccount = Account & StoredAssetData

export type StoredAsset = StoredUniqueAsset | StoredAccount

export type Claim = {
  subjectId: string
  plaintextClaim: string
  claimant: Requestor
}

export type StoredClaim = Claim & {
  delegateIdentityId?: string
  hashedClaim: string
  proof: string
  requestId: string
  subjectType: SubjectType
}

export type Endorsement = {
  claim: StoredClaim
  endorser: Requestor
}

export type StoredEndorsement = Endorsement & {
  signature: string
  delegateIdentityId?: string
  proof: string
  requestId: string
}

export type CarPurchaseStep = {
  id: string
  action: string
  name: string
  text: string
  value?: string
  executor: string
  reached: boolean
  requestId?: string
}

export type StoredCarPurchase = {
  steps: CarPurchaseStep[]
}

export type StoredUserPreference = {
  useExtension?: boolean
  useGuide?: boolean
}
