import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IconButton,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Locations from '../Locations'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { push } from 'connected-react-router'
import { getDelegates } from 'modules/delegateIdentities'
import AssignDelegateIdentity from './AssignDelegateIdentity'

export interface ContentProps {}

const ListDelegateIdentities: React.FC<ContentProps> = () => {
  const delegations = useSelector(getDelegates)
  const dispatch = useDispatch()

  return (
    <>
      <AssignDelegateIdentity />
      <Table style={{ width: '50%' }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <ListItem>Delegator</ListItem>
            </TableCell>
            <TableCell align="left">
              <ListItem>Delegate</ListItem>
            </TableCell>
            <TableCell align="left">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {delegations.map(delegation => (
            <TableRow key={delegation.requestId}>
              <TableCell>
                <ListItem
                  button
                  onClick={() =>
                    dispatch(push(Locations.ViewIdentity.buildUri({ id: delegation.delegator.address })))
                  }
                >
                  <Typography>{delegation.delegator.address}</Typography>
                </ListItem>
              </TableCell>
              <TableCell>
                <ListItem
                  button
                  onClick={() =>
                    dispatch(push(Locations.ViewIdentity.buildUri({ id: delegation.delegate.address })))
                  }
                >
                  <Typography>{delegation.delegate.address}</Typography>
                </ListItem>
              </TableCell>
              <TableCell>
                <IconButton
                  edge="start"
                  aria-label="proof"
                  onClick={() => dispatch(push(Locations.ViewRequest.buildUri({ id: delegation.requestId })))}
                >
                  <ReceiptIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default ListDelegateIdentities
