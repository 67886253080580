import React from 'react'
import { Field, Form, Formik } from 'formik'
import { Button, CircularProgress, FormControl, TextField, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useAddQuantity } from '../utils/useAddQuantity'
import { StoredAsset } from '../model'
import DisplayError from './DisplayError'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonContainer: {
    paddingTop: '1em',
  },
  error: {
    marginTop: '1em',
    color: theme.palette.error.main,
  },
}))

export interface AddQuantityProps {
  asset: StoredAsset
}

const AddQuantity: React.FC<AddQuantityProps> = ({ asset }) => {
  const classes = useStyles()
  const [result, error, submit] = useAddQuantity(asset)

  return (
    <Formik initialValues={{ quantity: '' }} onSubmit={submit}>
      {({ values, isSubmitting }) => (
        <Form className={classes.form}>
          <FormControl>
            <Field as={TextField} name="quantity" label="Quantity" />
          </FormControl>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting || values.quantity === ''}
            >
              {isSubmitting ? <CircularProgress size={24} /> : 'Add Quantity'}
            </Button>
          </div>
          <DisplayError errorTitle={error} error={result.error} />
        </Form>
      )}
    </Formik>
  )
}

export default AddQuantity
