import React, { useCallback } from 'react'
import { List } from '@material-ui/core'
import DnsRoundedIcon from '@material-ui/icons/DnsRounded'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import Locations from '../Locations'
import { QUANTIFIABLE, QuantifiableAssetType, Account, StoredAsset, StoredAssetType } from 'model'
import { makeStyles } from '@material-ui/styles'
import { ItemWithProof } from './ItemWithProof'
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyRounded'
import { getAssets, quantityString } from '../modules/assets'
import { AppState } from '../store'
import CreateAsset from './CreateAsset'

const useStyles = makeStyles({
  container: {
    width: '50%',
  },
})

interface AssetInstanceProps {
  assetType?: StoredAssetType
  id: StoredAsset
}

const AssetInstance: React.FC<AssetInstanceProps> = ({ id, assetType }) => {
  const dispatch = useDispatch()
  const isAccount = assetType?.type === QUANTIFIABLE

  const viewAsset = useCallback(
    () => dispatch(push(Locations.ViewAsset.buildUri({ typeId: id.assetTypeId, id: id.address }))),
    [dispatch, id.assetTypeId, id.address]
  )

  return (
    <ItemWithProof
      onClick={viewAsset}
      title={`${id.address} (${id.assetTypeId})`}
      requestId={id.requestId}
      avatarIcon={isAccount ? <AttachMoneyIcon /> : <DnsRoundedIcon />}
      preDetail={
        <>
          {isAccount ? quantityString(id as Account, assetType as QuantifiableAssetType) : ''}
          {isAccount ? <br /> : ''}
          Owner: {id.delegateIdentityId ? `${id.delegateIdentityId} on behalf of ${id.owner}` : id.owner}
        </>
      }
    />
  )
}

export interface ListAssetsProps {}

const ListAssets: React.FC<ListAssetsProps> = () => {
  const classes = useStyles()
  const assetInstances = useSelector(getAssets)
  const assetTypes = useSelector((state: AppState) => state.assetTypes)

  return (
    <>
      <CreateAsset />
      <List className={classes.container}>
        {assetInstances.map((i: StoredAsset) => (
          <AssetInstance
            id={i}
            key={i.requestId}
            assetType={assetTypes.find(t => t.address === i.assetTypeId)}
          />
        ))}
      </List>
    </>
  )
}

export default ListAssets
