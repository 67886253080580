import { put, takeEvery } from 'redux-saga/effects'
import { StoredIdentity } from '../model'
import LocalStorage from '../LocalStorage'
import { AppState } from '../store'
import { createSelector } from 'reselect'
import { selectDelegateIdentity } from './selectedDelegate'
import { client } from '../client/client'

export const IDENTITY_SELECTED = 'IDENTITY_SELECTED'
export const IDENTITY_NOT_FOUND = 'IDENTITY_NOT_FOUND'

export interface IdentitySelectedAction {
  type: typeof IDENTITY_SELECTED
  identity: StoredIdentity
}

function selectIdentityAction(identity: StoredIdentity): SelectedIdentityAction {
  return {
    type: IDENTITY_SELECTED,
    identity,
  }
}

export interface IdentityNotFoundAction {
  type: typeof IDENTITY_NOT_FOUND
  identity: StoredIdentity
}

function selectIdentityNotFoundAction(identity: StoredIdentity): SelectedIdentityAction {
  return {
    type: IDENTITY_NOT_FOUND,
    identity,
  }
}

export type SelectedIdentityAction = IdentitySelectedAction | IdentityNotFoundAction

export async function selectIdentity(identity: StoredIdentity): Promise<SelectedIdentityAction> {
  return await client
    .getIdentity(identity)
    .then(() => {
      return selectIdentityAction(identity)
    })
    .catch((error: any) => {
      if (error.event === 'EXTENSION_LOCKED') {
        alert(error.message)
      }
      return selectIdentityNotFoundAction(identity)
    })
}

const initialState: StoredIdentity | null = JSON.parse(
  localStorage.getItem(LocalStorage.SelectedIdentity) || 'null'
)

export default function selectedIdentityReducer(
  state = initialState,
  action: SelectedIdentityAction
): StoredIdentity | null {
  switch (action.type) {
    case IDENTITY_NOT_FOUND:
      return state
    case IDENTITY_SELECTED:
      return action.identity
    default:
      return state
  }
}

export const getSelectedIdentity = (state: AppState) => state.selectedIdentity
export const isAuthenticated = createSelector([getSelectedIdentity], i => !!i)

function* selectIdentityWorker(action: SelectedIdentityAction) {
  localStorage.setItem(LocalStorage.SelectedIdentity, JSON.stringify(action.identity))
  yield put(selectDelegateIdentity(null))
}

export function* selectIdentitySaga() {
  yield takeEvery(IDENTITY_SELECTED, selectIdentityWorker)
}
