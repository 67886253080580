import React from 'react'
import { List } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoredEndorsement } from '../model'
import CheckIcon from '@material-ui/icons/Done'
import { useSelector } from 'react-redux'
import { getEndorsements } from 'modules/endorsements'
import { ItemWithProof } from './ItemWithProof'
import CreateEndorsement from './CreateEndorsement'

const useStyles = makeStyles({
  container: {
    width: '50%',
  },
})

export interface ContentProps {}

interface EndorsementProps {
  id: StoredEndorsement
}

const Endorsement: React.FC<EndorsementProps> = ({ id }) => {
  return (
    <ItemWithProof
      title={`${id.claim.subjectId}: ${id.claim.plaintextClaim}`}
      requestId={id.requestId}
      avatarIcon={<CheckIcon />}
      preDetail={
        <>
          Endorsed by:{' '}
          {id.delegateIdentityId
            ? `${id.delegateIdentityId} on behalf of ${id.endorser.authenticator.address}`
            : id.endorser.authenticator.address}
        </>
      }
    />
  )
}

const ListEndorsements: React.FC<ContentProps> = () => {
  const classes = useStyles()
  const endorsements = useSelector(getEndorsements)

  return (
    <>
      <CreateEndorsement />
      <List className={classes.container}>
        {endorsements.map(i => (
          <Endorsement id={i} key={i.requestId} />
        ))}
      </List>
    </>
  )
}

export default ListEndorsements
