import CssBaseline from '@material-ui/core/CssBaseline'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import App from 'components/App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { configureStore } from './store'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

const store = configureStore()

const Root = () => (
  <Provider store={store}>
    <CssBaseline />
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </Provider>
)

render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
