import React, { useCallback } from 'react'
import { List } from '@material-ui/core'
import NoteIcon from '@material-ui/icons/NoteRounded'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import Locations from '../Locations'
import { StoredClaim } from 'model'
import { makeStyles } from '@material-ui/styles'
import { getClaims } from '../modules/claims'
import { ItemWithProof } from './ItemWithProof'
import CreateClaim, { SubjectType } from './CreateClaim'
import { useParams } from 'react-router'

const useStyles = makeStyles({
  container: {
    width: '50%',
  },
})

export interface ContentProps {}

interface ClaimProps {
  id: StoredClaim
}

const Claim: React.FC<ClaimProps> = ({ id }) => {
  const dispatch = useDispatch()
  const viewClaim = useCallback(
    () => dispatch(push(Locations.ViewClaim.buildUri({ requestId: id.requestId }))),
    [dispatch, id.requestId]
  )
  return (
    <ItemWithProof
      onClick={viewClaim}
      title={`${id.subjectId}: ${id.plaintextClaim}`}
      requestId={id.requestId}
      avatarIcon={<NoteIcon />}
    />
  )
}

const ListClaims: React.FC<ContentProps> = _ => {
  const classes = useStyles()
  const { subjectType } = useParams() as {
    subjectType: SubjectType
  }
  const claims = useSelector(getClaims)

  return (
    <>
      <CreateClaim subjectType={subjectType} />
      <List className={classes.container}>
        {claims
          .filter(c => c.subjectType === subjectType)
          .map(i => (
            <Claim id={i} key={i.requestId} />
          ))}
      </List>
    </>
  )
}

export default ListClaims
