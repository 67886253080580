import React from 'react'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useParams } from 'react-router'
import Link from '@material-ui/core/Link'
import Locations from '../Locations'

export interface ContentProps {}

const ViewIdentity: React.FC<ContentProps> = _ => {
  const { id } = useParams() as {
    id: string
  }
  const identities: [{ [x: string]: any }] = JSON.parse(localStorage.getItem('identities') || '[]')
  const identity = identities.filter(i => i['address'] === id)[0]
  if (identity === undefined) {
    return (
      <Typography color="textSecondary" align="center">
        Identity {id} not found
      </Typography>
    )
  }
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Address</b>
            </TableCell>
            <TableCell>{identity.address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Created by request ID</b>
            </TableCell>
            <TableCell>
              <Link href={`${Locations.ViewRequest.buildUri({ id: identity.requestId })}`}>
                {identity.requestId}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Keys</b>
            </TableCell>
            <TableCell>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>Key 1</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Protocol</b>
                      </TableCell>
                      <TableCell>{identity.protocol}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Public</b>
                      </TableCell>
                      <TableCell>{identity.publicKey || 'undefined'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Private</b>
                      </TableCell>
                      <TableCell>{identity.privateKey || 'undefined'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ViewIdentity
