import { StoredIdentity } from '../model'
import LocalStorage from '../LocalStorage'
import { AppState } from '../store'

export const IDENTITY_DELEGATE = 'IDENTITY_DELEGATE'

export interface SelectedDelegateIdentityAction {
  type: typeof IDENTITY_DELEGATE
  identity: StoredIdentity
}

export function selectDelegateIdentity(identity: StoredIdentity | null) {
  return {
    type: IDENTITY_DELEGATE,
    identity,
  }
}

const initialState: StoredIdentity | null = JSON.parse(
  localStorage.getItem(LocalStorage.SelectedDelegateIdentity) || 'null'
)

export default function selectedDelegateIdentityReducer(
  state = initialState,
  action: SelectedDelegateIdentityAction
): StoredIdentity | null {
  switch (action.type) {
    case IDENTITY_DELEGATE:
      localStorage.setItem(LocalStorage.SelectedDelegateIdentity, JSON.stringify(action.identity))
      return action.identity
    default:
      return state
  }
}

export const getSelectedDelegate = (state: AppState) => state.selectedDelegate
