import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { ApiError, ApiErrors } from '../client/ApiErrors'
import Locations from '../Locations'
import { Link } from '@material-ui/core'

export interface DisplayErrorProps {
  errorTitle?: string
  error?: ApiErrors | null
}

const DisplayError: React.FC<DisplayErrorProps> = ({ errorTitle, error }) => {
  return (
    <div>
      {error && (
        <Alert severity="error">
          {errorTitle && <AlertTitle>{errorTitle}</AlertTitle>}
          {error.errors && error.errors.length > 0 ? (
            error.errors.length > 1 ? (
              <ul>
                {error.errors.map(e => (
                  <li>
                    <ErrorRow error={e} />
                  </li>
                ))}
              </ul>
            ) : (
              <ErrorRow error={error.errors[0]} />
            )
          ) : (
            error.message
          )}
          {error.requestId && (
            <div>
              <b>Proof of failure</b> -{' '}
              <Link href={`${Locations.ViewRequest.buildUri({ id: error.requestId })}`}>
                {error.requestId}
              </Link>
            </div>
          )}
        </Alert>
      )}
    </div>
  )
}

interface ErrorRowProps {
  error: ApiError
}

const ErrorRow: React.FC<ErrorRowProps> = ({ error }) => {
  return (
    <div>
      <b>{error.errorType}</b> <i>(Error {error.errorCode})</i> - {error.message}
    </div>
  )
}

export default DisplayError
