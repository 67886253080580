export function addOrReplace<A>(items: A[], newItems: A[], key: (a: A) => string): A[] {
  let newArray = [...items]
  newItems.forEach(newItem => {
    const index = newArray.findIndex(a => key(a) === key(newItem))
    if (~index) newArray[index] = newItem
    else newArray = [...newArray, newItem]
  })
  return newArray
}

export function capitaliseFirst(string: string): string {
  return string[0].toUpperCase().concat(string.slice(1))
}
