import React, { useCallback } from 'react'
import { IconButton, List, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PeopleIcon from '@material-ui/icons/PeopleAlt'
import KeyIcon from '@material-ui/icons/VpnKey'
import DisabledKeyIcon from '@material-ui/icons/VpnKeyOutlined'
import DelegateIcon from '@material-ui/icons/SupervisedUserCircle'
import DisabledDelegateIcon from '@material-ui/icons/SupervisedUserCircleOutlined'
import { useDispatch, useSelector } from 'react-redux'
import Locations from '../Locations'
import { StoredIdentity } from 'model'
import { push } from 'connected-react-router'
import { getIdentities, deleteIdentity } from 'modules/identities'
import { getSelectedDelegate, selectDelegateIdentity } from 'modules/selectedDelegate'
import {
  getSelectedIdentity,
  selectIdentity,
  IDENTITY_NOT_FOUND,
  IDENTITY_SELECTED,
} from 'modules/selectedIdentity'
import { ItemWithProof } from './ItemWithProof'
import { ItemWithoutProof } from './ItemWithoutProof'
import { getDelegates } from '../modules/delegateIdentities'
import CreateIdentity from './CreateIdentity'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const useStyles = makeStyles({
  container: {
    width: '50%',
  },
})

export interface ContentProps {}
interface IdentityPropsBase {
  id: StoredIdentity
}

interface IdentityProps extends IdentityPropsBase {
  id: StoredIdentity
  isSelectedIdentity: boolean
  isSelectedDelegate: boolean
}

const NonAuthorizedIdentity: React.FC<IdentityPropsBase> = ({ id }) => {
  const dispatch = useDispatch()

  return (
    <ItemWithoutProof
      avatarIcon={<PeopleIcon />}
      title={id.address}
      buttons={
        <>
          <IconButton aria-label="select" onClick={async () => dispatch(await selectIdentity(id))}>
            <Tooltip title="Select authenticating identity">
              <DisabledKeyIcon />
            </Tooltip>
          </IconButton>
        </>
      }
    />
  )
}

const NonExistingIdentity: React.FC<IdentityPropsBase> = ({ id }) => {
  const dispatch = useDispatch()
  return (
    <ItemWithoutProof
      avatarIcon={<PeopleIcon />}
      title={id.address}
      buttons={
        <>
          <IconButton>
            <Tooltip title="Identity does not exist on the platform">
              <AnnouncementIcon />
            </Tooltip>
          </IconButton>
          <IconButton aria-label="select" onClick={() => dispatch(deleteIdentity(id))}>
            <Tooltip title="Delete identity">
              <DeleteForeverIcon />
            </Tooltip>
          </IconButton>
        </>
      }
    />
  )
}

const Identity: React.FC<IdentityProps> = ({ id, isSelectedIdentity, isSelectedDelegate }) => {
  const dispatch = useDispatch()
  const delegates = useSelector(getDelegates)
  const selectedIdentity = useSelector(getSelectedIdentity)
  const viewIdentity = useCallback(
    () => dispatch(push(Locations.ViewIdentity.buildUri({ id: id.address }))),
    [dispatch, id.address]
  )
  const validDelegate = delegates.some(
    d => d.delegator.address === selectedIdentity?.address && d.delegate.address === id.address
  )

  return (
    <ItemWithProof
      avatarIcon={<PeopleIcon />}
      onClick={viewIdentity}
      title={id.address}
      requestId={id.requestId}
      buttons={
        <>
          <IconButton aria-label="select" onClick={async () => dispatch(await selectIdentity(id))}>
            <Tooltip title="Select authenticating identity">
              {isSelectedIdentity ? <KeyIcon color="primary" /> : <DisabledKeyIcon />}
            </Tooltip>
          </IconButton>
          <IconButton
            edge="end"
            aria-label="select"
            disabled={isSelectedIdentity || !validDelegate}
            onClick={() =>
              isSelectedDelegate
                ? dispatch(selectDelegateIdentity(null))
                : dispatch(selectDelegateIdentity(id))
            }
          >
            <Tooltip title={isSelectedDelegate ? 'De-select delegate identity' : 'Select delegate identity'}>
              {isSelectedDelegate ? <DelegateIcon color="primary" /> : <DisabledDelegateIcon />}
            </Tooltip>
          </IconButton>
        </>
      }
    />
  )
}

const ListIdentities: React.FC<ContentProps> = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const identities = useSelector(getIdentities)
  const selectedIdentity = useSelector(getSelectedIdentity)
  const selectedDelegate = useSelector(getSelectedDelegate)
  identities.forEach(async identity => {
    const selectIdentityAction = await selectIdentity(identity)
    if (
      (identity.present && selectIdentityAction.type === IDENTITY_NOT_FOUND) ||
      (!identity.present && selectIdentityAction.type === IDENTITY_SELECTED)
    ) {
      dispatch(selectIdentityAction)
    }
  })

  return (
    <>
      <CreateIdentity />
      <List className={classes.container}>
        {identities.map(i => {
          if (!i.present) return <NonExistingIdentity key={i.address} id={i} />
          else if (!selectedIdentity) return <NonAuthorizedIdentity key={i.address} id={i} />
          else
            return (
              <Identity
                id={i}
                key={i.requestId}
                isSelectedIdentity={i.address === selectedIdentity?.address}
                isSelectedDelegate={i.address === selectedDelegate?.address}
              />
            )
        })}
      </List>
    </>
  )
}

export default ListIdentities
