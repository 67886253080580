import { ProtocolIdType } from 'core-sdk-typescript'
import { StoredAsset, StoredIdentity } from 'model'
import { clientLib, ClientLib } from './clientLib'
import { clientExt, ClientExt } from './clientExt'
import { Requestor } from './requestor'

export type ClientProvider = ClientLib | ClientExt

class Client {
  provider: ClientProvider = clientLib

  setProvider(provider: ClientProvider) {
    this.provider = provider
  }

  isExtensionAvailable() {
    return clientExt.isExtensionAvailable()
  }

  async getAssetType(assetType: string, selectedIdentity: StoredIdentity, selectedDelegate?: StoredIdentity) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.getAssetType(assetType, requestor)
  }

  async getAsset(
    assetId: string,
    assetTypeId: string,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.getAsset(assetId, assetTypeId, requestor)
  }

  async getIdentityClaim(
    subjectId: string,
    claim: string,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.getIdentityClaim(subjectId, claim, requestor)
  }

  async getAssetTypeClaim(
    subjectId: string,
    claim: string,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.getAssetTypeClaim(subjectId, claim, requestor)
  }

  async getAssetClaim(
    subjectId: string,
    subjectTypeId: string,
    claim: string,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.getAssetClaim(subjectId, subjectTypeId, claim, requestor)
  }

  async getIdentityClaimEndorsement(
    subjectId: string,
    claim: string,
    endorserId: string,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.getIdentityClaimEndorsement(subjectId, claim, endorserId, requestor)
  }

  async getAssetTypeClaimEndorsement(
    subjectId: string,
    claim: string,
    endorserId: string,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.getAssetTypeClaimEndorsement(subjectId, claim, endorserId, requestor)
  }

  async getAssetClaimEndorsement(
    subjectId: string,
    subjectTypeId: string,
    claim: string,
    endorserId: string,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.getAssetClaimEndorsement(
      subjectId,
      subjectTypeId,
      claim,
      endorserId,
      requestor
    )
  }

  async getIdentity(identity: StoredIdentity) {
    const requestor: Requestor = new Requestor(identity)
    return await this.provider.getIdentity(requestor)
  }

  async createIdentity(protocolId: ProtocolIdType, identityId: string) {
    return await this.provider.createIdentity(protocolId, identityId)
  }

  async createAssetType(input: any, selectedIdentity: StoredIdentity, selectedDelegate?: StoredIdentity) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.createAssetType(input, requestor)
  }

  async createAsset(input: any, selectedIdentity: StoredIdentity, selectedDelegate?: StoredIdentity) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.createAsset(input, requestor)
  }

  async assignDelegate(delegator: StoredIdentity, delegate: StoredIdentity) {
    return await this.provider.assignDelegate(delegator, delegate)
  }

  async getHealthChecks() {
    return await this.provider.getHealthChecks()
  }

  async createIdentityClaims(
    input: any,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.createIdentityClaims(input, requestor)
  }

  async createAssetTypeClaims(
    input: any,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.createAssetTypeClaims(input, requestor)
  }

  async createAssetClaims(input: any, selectedIdentity: StoredIdentity, selectedDelegate?: StoredIdentity) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.createAssetClaims(input, requestor)
  }

  async createIdentityClaimEndorsement(
    input: any,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.createIdentityClaimEndorsement(input, requestor)
  }

  async createAssetTypeClaimEndorsement(
    input: any,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.createAssetTypeClaimEndorsement(input, requestor)
  }

  async createAssetClaimEndorsement(
    input: any,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.createAssetClaimEndorsement(input, requestor)
  }

  async getProof(requestId: string, requestorIdentity: StoredIdentity) {
    const requestor: Requestor = new Requestor(requestorIdentity)
    return await this.provider.getProof(requestId, requestor)
  }

  async transferOwnership(
    input: any,
    asset: StoredAsset,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.transferOwnership(input, asset, requestor)
  }

  async transferQuantity(
    input: any,
    account: StoredAsset,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.transferQuantity(input, account, requestor)
  }

  async addQuantity(
    input: any,
    account: StoredAsset,
    selectedIdentity: StoredIdentity,
    selectedDelegate?: StoredIdentity
  ) {
    const requestor: Requestor = new Requestor(selectedIdentity, selectedDelegate)
    return await this.provider.addQuantity(input, account, requestor)
  }
}

const client = new Client()

export { client }
