import React from 'react'
import { StoredAsset, UNIQUE } from '../model'
import { useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import { getAssetTypes } from '../modules/assetTypes'
import { makeStyles } from '@material-ui/styles'
import { Input, useCreate } from '../utils/useCreate'
import { sleep } from '../utils/sleep'
import { assetsCreatedOrModified } from '../modules/assets'
import DisplayError from './DisplayError'
import { client } from '../client/client'
import { StoredIdentity } from 'model'
import { CREATE_ASSET_GUIDE } from '../client/guide'
import { UserGuide } from './UserGuide'

interface AssetChoice {
  assetTypeId: string
  address: string
  quantity: string
}

async function createAsset({
  input,
  selectedIdentity,
  selectedDelegate,
}: Input<AssetChoice>): Promise<StoredAsset[]> {
  if (selectedIdentity === null) {
    throw new Error('No identity selected')
  }

  const createResponse = await client.createAsset(
    input,
    selectedIdentity as StoredIdentity,
    selectedDelegate as StoredIdentity
  )
  await sleep(1000)
  const response = await client.getAsset(
    input.address,
    input.assetTypeId,
    selectedIdentity as StoredIdentity,
    selectedDelegate as StoredIdentity
  )

  return [
    {
      proof: createResponse.proof,
      requestId: createResponse.requestId,
      owner: response.ownerId,
      delegateIdentityId: response.delegateIdentityId,
      ...input,
    },
  ]
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonContainer: {
    paddingTop: '1em',
  },
  error: {
    marginTop: '1em',
    color: theme.palette.error.main,
  },
}))

export interface CreateAssetProps {}

const CreateAsset: React.FC<CreateAssetProps> = () => {
  const classes = useStyles()
  const [result, error, submit] = useCreate(createAsset, assetsCreatedOrModified)
  const assetTypes = useSelector(getAssetTypes)
  const initialAsset: AssetChoice = {
    assetTypeId: '',
    address: '',
    quantity: '',
  }

  return (
    <div>
      <UserGuide id={CREATE_ASSET_GUIDE} />
      <Formik initialValues={initialAsset} onSubmit={submit}>
        {({ values, isSubmitting }) => (
          <Form className={classes.form}>
            <div className="assettype">
              <FormControl>
                <InputLabel>Asset Type</InputLabel>
                <Field as={Select} name="assetTypeId" type="string" style={{ minWidth: 120 }}>
                  {assetTypes.map(assetType => (
                    <MenuItem key={assetType.address} value={assetType.address}>
                      {assetType.address}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </div>
            <div className="address">
              <FormControl>
                <Field as={TextField} name="address" type="string" label="Address" />
              </FormControl>
            </div>
            <div className="quantity">
              <FormControl>
                <Field
                  as={TextField}
                  name="quantity"
                  label="Quantity"
                  disabled={
                    !values.assetTypeId ||
                    assetTypes.find(t => t.address === values.assetTypeId)?.type === UNIQUE
                  }
                />
              </FormControl>
            </div>
            <div className={classes.buttonContainer}>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} /> : 'Create'}
              </Button>
            </div>
            <DisplayError errorTitle={error} error={result.error} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateAsset
