import { StoredEndorsement } from '../model'
import LocalStorage from '../LocalStorage'
import { AppState } from '../store'
import { select, takeEvery } from 'redux-saga/effects'
import { addOrReplace } from '../utils/arrayAndStringsUtils'

export const ENDORSEMENT_CREATED = 'ENDORSEMENT_CREATED'

interface EndorsementCreated {
  type: typeof ENDORSEMENT_CREATED
  endorsement: StoredEndorsement
}

export type EndorsementAction = EndorsementCreated

export function endorsementCreated(endorsement: StoredEndorsement): EndorsementAction {
  return {
    type: ENDORSEMENT_CREATED,
    endorsement,
  }
}

const initialState: StoredEndorsement[] = JSON.parse(localStorage.getItem(LocalStorage.Endorsements) || '[]')

export default function endorsementReducer(
  state = initialState,
  action: EndorsementAction
): StoredEndorsement[] {
  switch (action.type) {
    case ENDORSEMENT_CREATED:
      return addOrReplace(
        state,
        [action.endorsement],
        e => e.endorser.authenticator.address + ':' + e.claim.hashedClaim
      )
    default:
      return state
  }
}

export const getEndorsements = (state: AppState) => state.endorsements

function* endorsementCreatedWorker() {
  const state: StoredEndorsement[] = yield select(getEndorsements)
  localStorage.setItem(LocalStorage.Endorsements, JSON.stringify(state))
}

export function* endorsementCreatedSaga() {
  yield takeEvery(ENDORSEMENT_CREATED, endorsementCreatedWorker)
}
