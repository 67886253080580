import { MutationResult, useMutation } from 'react-query'
import { FormikHelpers } from 'formik'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedIdentity } from '../modules/selectedIdentity'
import { getSelectedDelegate } from '../modules/selectedDelegate'
import { StoredIdentity } from '../model'
import { getIdentities } from 'modules/identities'
import Locations from 'Locations'
import { push } from 'connected-react-router'
import { proofRetrieved } from 'modules/proof'
import { client } from '../client/client'

export interface Input<A> {
  input: A
  selectedIdentity: StoredIdentity | null
  selectedDelegate: StoredIdentity | null
  identities: StoredIdentity[]
}

interface ProofChoice {
  requestId: string
}

async function getProof({ input, selectedIdentity }: Input<ProofChoice>): Promise<any> {
  if (selectedIdentity === null) {
    throw new Error('No identity selected')
  }
  const id = input.requestId.replace(/[^a-f0-9-]/gi, '')

  const response = await client.getProof(id, selectedIdentity as StoredIdentity)
  return JSON.stringify(response)
}

export function useReadProof(): [
  MutationResult<string, Error>,
  string,
  (a: ProofChoice, formik: FormikHelpers<ProofChoice>) => Promise<string | undefined>
] {
  const [mutate, result] = useMutation(getProof)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const selectedIdentity = useSelector(getSelectedIdentity)
  const selectedDelegate = useSelector(getSelectedDelegate)
  const identities = useSelector(getIdentities)

  const submit = useCallback(
    async (a: ProofChoice, formik: FormikHelpers<ProofChoice>) => {
      setError('')
      try {
        const newA: any = await mutate({ input: a, selectedIdentity, selectedDelegate, identities })
        if (!newA) {
          setError(`Couldn't perform getProof action`)
          return
        }
        dispatch(proofRetrieved(newA))
        dispatch(push(Locations.ViewRequest.buildUri({ id: JSON.parse(newA).requestId })))
        return newA
      } catch (e: any) {
        setError(e.message)
      }
    },
    [dispatch, mutate, setError, selectedIdentity, selectedDelegate, identities]
  )

  return [result, error, submit]
}
