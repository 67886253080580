import { MutationResult, useMutation } from 'react-query'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedIdentity } from '../modules/selectedIdentity'
import { getSelectedDelegate } from '../modules/selectedDelegate'
import { StoredAccount, StoredAsset, StoredIdentity } from '../model'
import { sleep } from './sleep'
import { FormikHelpers } from 'formik'
import { assetsCreatedOrModified } from '../modules/assets'
import { getIdentities } from 'modules/identities'
import { client } from '../client/client'

export interface Input {
  quantity: string
}

export interface MutateInput {
  input: Input
  account: StoredAsset
  selectedIdentity: StoredIdentity | null
  selectedDelegate: StoredIdentity | null
  identities: StoredIdentity[]
}

async function addQuantity(i: MutateInput): Promise<StoredAccount[]> {
  if (i.selectedIdentity === null) {
    throw new Error('No identity selected')
  }
  const addQuantityResponse = await client.addQuantity(
    i.input,
    i.account,
    i.selectedIdentity,
    i.selectedDelegate as StoredIdentity
  )
  await sleep(1000)
  const response = await client.getAsset(
    i.account.address,
    i.account.assetTypeId,
    i.selectedIdentity,
    i.selectedDelegate as StoredIdentity
  )

  return [
    {
      proof: addQuantityResponse.proof,
      requestId: addQuantityResponse.requestId,
      owner: response.ownerId,
      address: response.assetId,
      assetTypeId: response.assetTypeId,
      quantity: response.quantity,
    },
  ]
}

export function useAddQuantity(
  account: StoredAsset
): [
  MutationResult<StoredAccount[], Error>,
  string,
  (input: Input, formik: FormikHelpers<Input>) => Promise<StoredAccount[] | undefined>
] {
  const [mutate, result] = useMutation(addQuantity)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const selectedIdentity = useSelector(getSelectedIdentity)
  const selectedDelegate = useSelector(getSelectedDelegate)
  const identities = useSelector(getIdentities)

  const doIt = useCallback(
    async (input: Input, formik: FormikHelpers<Input>) => {
      setError('')
      try {
        const modifiedAccount = await mutate({
          account,
          input,
          selectedIdentity,
          selectedDelegate,
          identities,
        })
        if (!modifiedAccount) {
          setError(`Couldn't perform add quantity action`)
          return
        }
        dispatch(assetsCreatedOrModified(modifiedAccount))
        formik.resetForm()
        return modifiedAccount
      } catch (e: any) {
        setError(e.message)
      }
    },
    [dispatch, mutate, setError, account, selectedIdentity, selectedDelegate, identities]
  )

  return [result, error, doIt]
}
