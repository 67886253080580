import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import Drawer, { DrawerProps } from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PeopleIcon from '@material-ui/icons/People'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import DelegateIcon from '@material-ui/icons/GroupAdd'
import DnsRoundedIcon from '@material-ui/icons/DnsRounded'
import NoteIcon from '@material-ui/icons/Note'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import Lock from '@material-ui/icons/Lock'
import { Omit } from '@material-ui/types'
import Locations from 'Locations'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { Theme } from '@material-ui/core'
import { isAuthenticated } from 'modules/selectedIdentity'
import { getAssetTypes } from '../modules/assetTypes'
import { currentEnvironment } from '../config'
import { IDENTITIES_SUBJECT } from './CreateClaim'
import { Button } from '@material-ui/core'
import { client } from '../client/client'
import { clientLib } from '../client/clientLib'
import { clientExt } from '../client/clientExt'
import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { ThumbDown, ThumbUp } from '@material-ui/icons'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import GavelIcon from '@material-ui/icons/Gavel'
import { getUserPreference, selectUserPreference } from '../modules/userPreference'

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: '#b6ffe1',
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: '#b6ffe1',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#00251c',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: '#b6ffe1',
  },
  itemActiveItem: {
    color: '#b6ffe1',
  },
  checkBox: {
    color: '#00251c',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}))

function storeHealth(storeName: string, store: any) {
  const status = (store.canRead === undefined || store.canRead) && store.canWrite
  return {
    item: storeName,
    status,
    icon: status ? <ThumbUp /> : <ThumbDown />,
    color: status ? 'green' : 'red',
  }
}

function healthCheck() {
  return client
    .getHealthChecks()
    .then(response => {
      const health = [
        storeHealth('broker', response.broker),
        //storeHealth('requestStore', response.requestStore),
        storeHealth('assetStore', response.assetStore),
        storeHealth('claimStore', response.claimStore),
        storeHealth('endorsementStore', response.endorsementStore),
        storeHealth('permissionStore', response.permissionStore),
        storeHealth('transactionStore', response.transactionStore),
        storeHealth('transferStore', response.transferStore),
        storeHealth('transferByIdentityStore', response.transferByIdentityStore),
      ]
      return {
        health,
        message: 'The platform is up and running. See health indicators below.',
      }
    })
    .catch((error: Error) => {
      return {
        message: error.message,
        health: [],
      }
    })
}

export interface HealthDialogProps {
  open: boolean
  onClose: (value: string) => void
}

function SimpleDialog(props: HealthDialogProps) {
  const { onClose, open } = props
  const [state, setState] = useState({
    check: true,
    message: '',
    health: [] as any,
  })

  const handleEnter = () => {
    setState({
      check: true,
      message: '',
      health: [],
    })
  }

  if (state.check) {
    healthCheck().then(response => {
      setState({
        check: false,
        message: response.message,
        health: response.health,
      })
    })
  }

  return (
    <Dialog onEnter={handleEnter} open={open}>
      <DialogTitle>Platform Health Status</DialogTitle>
      <List>
        <ListItem>
          <ListItemText key="message">{state.message}</ListItemText>
        </ListItem>
      </List>
      <List>
        {state.health.map((el: any) => (
          <ListItem key={el.item.toString()}>
            <ListItemText>{el.item}</ListItemText>
            <ListItemIcon style={{ color: el.color }}>{el.icon}</ListItemIcon>
          </ListItem>
        ))}
      </List>
      <Button onClick={() => onClose('Close Dialog')}>Close</Button>
    </Dialog>
  )
}

export interface NavigatorProps extends Omit<DrawerProps, 'classes'> {}

const Navigator: React.FC<NavigatorProps> = props => {
  const { ...other } = props
  const classes = useStyles()
  const authenticated = useSelector(isAuthenticated)
  const assetTypes = useSelector(getAssetTypes)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value: string) => {
    setOpen(false)
  }

  const categories = [
    {
      id: 'Data explorer',
      name: 'dataexplorer',
      children: [
        {
          id: 'Identities',
          name: 'identities',
          icon: <PeopleIcon />,
          location: Locations.ListIdentities.uri,
          active: false,
          disabled: false,
        },
        {
          id: 'Delegates',
          name: 'delegates',
          icon: <DelegateIcon />,
          location: Locations.ListDelegateIdentities.uri,
          active: false,
          disabled: !authenticated,
        },
        {
          id: 'Asset types',
          name: 'assettypes',
          icon: <GroupWorkIcon />,
          location: Locations.ListAssetTypes.uri,
          disabled: !authenticated,
        },
        {
          id: 'Assets',
          name: 'assets',
          icon: <DnsRoundedIcon />,
          location: Locations.ListAssets.uri,
          disabled: !authenticated || !assetTypes.length,
        },
        {
          id: 'Claims',
          name: 'claims',
          icon: <NoteIcon />,
          location: Locations.ListClaims.buildUri(IDENTITIES_SUBJECT),
          disabled: !authenticated,
        },
        {
          id: 'Endorsements',
          name: 'endorsements',
          icon: <NoteAddIcon />,
          location: Locations.ListEndorsements.uri,
          disabled: !authenticated,
        },
        {
          id: 'Proofs',
          name: 'proofs',
          icon: <GavelIcon />,
          location: Locations.ViewRequest.uri,
          disabled: !authenticated,
        },
      ],
    },
    {
      id: 'Use cases',
      name: 'usecases',
      children: [
        {
          id: 'Car purchase',
          name: 'carpurchase',
          icon: <DirectionsCarIcon />,
          location: Locations.CarPurchase.uri,
          active: false,
          disabled: false,
        },
      ],
    },
    {
      id: 'Settings',
      name: 'settings',
      children: [
        {
          id: 'Authentication',
          name: 'authentication',
          icon: <Lock />,
          location: Locations.Authentication.uri,
          active: false,
          disabled: false,
        },
      ],
    },
  ]

  const [extensionAvailable, setExtensionAvailable] = React.useState(false)
  const userPreference = useSelector(getUserPreference)

  useEffect(() => {
    setTimeout(() => {
      const isExtensionAvailable = client.isExtensionAvailable()
      if (extensionAvailable !== isExtensionAvailable) {
        setExtensionAvailable(isExtensionAvailable)
      }
    }, 500)
  }, [setExtensionAvailable, extensionAvailable])

  useEffect(() => {
    client.setProvider(userPreference.useExtension ? clientExt : clientLib)
  }, [userPreference.useExtension])

  const handleExtensionChange = (e: any) => {
    dispatch(selectUserPreference({ ...userPreference, useExtension: e.target.checked }))
  }

  const handleGuideChange = (e: any) => {
    dispatch(selectUserPreference({ ...userPreference, useGuide: e.target.checked }))
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemText
            classes={{ primary: classes.firebase, secondary: classes.itemActiveItem }}
            primary={'iov42 Playground'}
            secondary={currentEnvironment}
          />
        </ListItem>
        {extensionAvailable && (
          <Box mx={2}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={userPreference.useExtension}
                    style={{
                      color: '#b6ffe1',
                    }}
                    size="small"
                    onChange={handleExtensionChange}
                    name="useExtension"
                  />
                }
                label={<Typography style={{ color: '#b6ffe1' }}>Use extension</Typography>}
              />
            </FormGroup>
          </Box>
        )}
        <Box mx={2}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={userPreference.useGuide}
                  style={{
                    color: '#b6ffe1',
                  }}
                  size="small"
                  onChange={handleGuideChange}
                  name="useGuide"
                />
              }
              label={<Typography style={{ color: '#b6ffe1' }}>Use guide</Typography>}
            />
          </FormGroup>
        </Box>
        <Box mx={3} p={1}>
          <Button
            variant="outlined"
            size="small"
            style={{
              color: '#b6ffe1',
              borderColor: '#b6ffe1',
            }}
            onClick={handleClickOpen}
          >
            Check Platform Health
          </Button>
          <SimpleDialog open={open} onClose={handleClose} />
        </Box>
        {categories.map(({ id, name, children }) => (
          <React.Fragment key={id}>
            <div className={name}>
              <ListItem className={clsx(classes.item, classes.itemActiveItem)}>
                <ListItemText classes={{ primary: classes.categoryHeaderPrimary }}>{id}</ListItemText>
              </ListItem>
            </div>
            {children.map(c => (
              <div className={c.name} key={c.name}>
                <ListItem
                  disabled={c.disabled}
                  key={c.id}
                  button
                  className={clsx(classes.item, c.active && classes.itemActiveItem)}
                  onClick={() => dispatch(push(c.location))}
                >
                  <ListItemIcon className={classes.itemIcon}>{c.icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {c.id}
                  </ListItemText>
                </ListItem>
              </div>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  )
}

export default Navigator
