import { makeStyles } from '@material-ui/styles'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Theme } from '@material-ui/core'
import React from 'react'
import { ProofStatus, StatusChip } from './StatusChip'
import { Proof, ProofDetails } from './ProofDetails'

function proofStatus(proof: Proof): ProofStatus {
  const voteNodes = proof.proof.nodes.filter(n => n.id._type === 'Vote')
  if (voteNodes.length > 0 && voteNodes.every(n => n.payload?.includes('"Yes"'))) return 'Ok'
  return 'Not ok'
}

interface VisualiseProofJsonProps {
  proof: Proof
}

const useStyles = makeStyles((theme: Theme) => ({
  status: {
    alignSelf: 'center',
  },
  pre: {
    marginLeft: theme.spacing(),
    textOverflow: 'ellipsis',
    overflow: 'auto',
    // NOTE: 255px is the width of the left-hand sidebar
    maxWidth: 'calc(90vw - 255px)',
  },
  details: {
    flexDirection: 'column',
  },
}))

export const VisualiseProofJson: React.FC<VisualiseProofJsonProps> = ({ proof }) => {
  const classes = useStyles()
  return (
    <div>
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <StatusChip className={classes.status} status={proofStatus(proof)} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <ProofDetails proof={proof} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
