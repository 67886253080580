import React from 'react'
import Typography from '@material-ui/core/Typography'

export interface ContentProps {}

const Settings: React.FC<ContentProps> = _ => {
  return (
    <Typography color="textSecondary" align="center">
      Not yet implemented
    </Typography>
  )
}

export default Settings
