import React from 'react'
import { push } from 'connected-react-router'
import { List } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/styles'
import { ItemWithProof } from './ItemWithProof'
import { ItemWithoutProof } from './ItemWithoutProof'
import { CarPurchaseStep } from 'model'
import { getCarPurchase } from '../modules/carPurchase'
import Locations from '../Locations'
import Typography from '@material-ui/core/Typography'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Box from '@material-ui/core/Box'
import Slider from '@material-ui/core/Slider'

const useStyles = makeStyles({
  container: {
    width: '50%',
  },
})

interface CarPurchaseProps {
  id: CarPurchaseStep
}

const CarPurchaseStepAchieved: React.FC<CarPurchaseProps> = ({ id }) => {
  const dispatch = useDispatch()

  return (
    <ItemWithProof
      onClick={() => dispatch(push(Locations.ViewRequest.buildUri({ id: id.requestId as string })))}
      title={id.name}
      requestId={id.requestId !== undefined ? id.requestId : ''}
      avatarIcon={<CheckBoxIcon style={{ color: 'green' }} />}
    />
  )
}

const CarPurchaseStepNotAchieved: React.FC<CarPurchaseProps> = ({ id }) => {
  return (
    <ItemWithoutProof
      title={id.name}
      avatarIcon={<CheckBoxOutlineBlankIcon />}
      preDetail={
        <>
          <span dangerouslySetInnerHTML={{ __html: id.text }} />
          <br />
        </>
      }
    />
  )
}

export interface ListCarPurchaseProps {}

const CarPurchase: React.FC<ListCarPurchaseProps> = () => {
  const classes = useStyles()
  const carPurchase = useSelector(getCarPurchase)

  function getMarks() {
    const marks = []
    for (let i = 0; i <= 9; i++) {
      marks.push({
        value: i,
        label: `${i} of 9`,
      })
    }
    return marks
  }

  const CustomSlider = withStyles(theme => ({
    track: {
      height: 2,
      color: 'green',
    },
    disabled: {
      color: 'green',
    },
  }))(Slider)
  const stepsCompleted = carPurchase.steps.filter(step => step.reached).length

  return (
    <>
      <Box border={1} borderColor="grey.500" borderRadius={16} p={6}>
        <Typography color="textSecondary" align="left">
          In this scenario Alice wants to sell her car to Bob. Bob only wants to buy the car if Alice can
          prove that the first registration of the car was not longer than ten years ago (assuming the
          reference year is 2020).
          <br />
          Also in this scenario we assume that there is a fictional authority responsible for motor vehicles
          (MVA - like the DMV in the US or the DVLA in Britain). The MVA identity has to create an Asset Type
          "Car" as preparation step.
          <br />
          Follow the steps described and each of them will become marked as done as you go along.
          <br />
          <br />
          Steps completed:
          <br />
        </Typography>
        <CustomSlider
          disabled
          defaultValue={stepsCompleted}
          marks={getMarks()}
          min={0}
          max={9}
          valueLabelDisplay="off"
        />
      </Box>
      <List className={classes.container}>
        {carPurchase.steps.map(i => {
          if (i.reached) return <CarPurchaseStepAchieved key={i.id} id={i} />
          else return <CarPurchaseStepNotAchieved key={i.id} id={i} />
        })}
      </List>
    </>
  )
}

export default CarPurchase
