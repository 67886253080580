import { select, takeEvery } from 'redux-saga/effects'
import { AssetTypeType, StoredAssetType } from '../model'
import LocalStorage from '../LocalStorage'
import { AppState } from '../store'

export const SELECT_TYPE = 'SELECT_TYPE'
export const ASSET_TYPE_CREATED = 'ASSET_TYPE_CREATED'

interface SelectTypeAction {
  type: typeof SELECT_TYPE
  assetTypeType: AssetTypeType
}

interface AssetTypeCreated {
  type: typeof ASSET_TYPE_CREATED
  assetType: StoredAssetType
}

export type AssetTypeAction = SelectTypeAction | AssetTypeCreated

export function assetTypeCreated(assetType: StoredAssetType): AssetTypeAction {
  return {
    type: ASSET_TYPE_CREATED,
    assetType,
  }
}

const initialState: StoredAssetType[] = JSON.parse(localStorage.getItem(LocalStorage.AssetTypes) || '[]')

export default function assetTypeReducer(state = initialState, action: AssetTypeAction): StoredAssetType[] {
  switch (action.type) {
    case ASSET_TYPE_CREATED:
      return [...state, action.assetType]
    default:
      return state
  }
}

export const getAssetTypes = (state: AppState) => state.assetTypes

function* assetTypeCreatedWorker() {
  const state: StoredAssetType[] = yield select(getAssetTypes)
  localStorage.setItem(LocalStorage.AssetTypes, JSON.stringify(state))
}

export function* assetTypeCreatedSaga() {
  yield takeEvery(ASSET_TYPE_CREATED, assetTypeCreatedWorker)
}
