import { v4 as uuidv4 } from 'uuid'
import { StoredAsset, StoredIdentity } from 'model'
import { PlatformUtils, ProtocolIdType } from 'core-sdk-typescript'
import { Requestor } from './requestor'
import { Iov42 } from './extension.js'

export class ClientExt {
  iov42: Iov42
  extensionAvailable = false
  platformUtils: PlatformUtils

  constructor() {
    this.iov42 = new Iov42(this.setExtensionAvailable)
    this.platformUtils = new PlatformUtils()
  }

  setExtensionAvailable() {
    clientExt.extensionAvailable = true
  }

  isExtensionAvailable() {
    return this.extensionAvailable
  }

  execPluginActions(inputActions: any) {
    return this.iov42
      .execPluginActions(inputActions)
      .then(([{ error, response }]: any[]) => {
        if (error) {
          throw new Error(JSON.stringify(error))
        }
        return response
      })
      .catch((error: Error) => {
        if (error === null) {
          throw new Error('Operation was cancelled by user')
        } else {
          throw error
        }
      })
  }

  async getAssetType(assetType: string, requestor: Requestor) {
    return await this.iov42.getAssetType(
      assetType,
      requestor.getIdentityId(),
      requestor.getDelegatorId() as undefined
    )
  }

  async getAsset(assetId: string, assetTypeId: string, requestor: Requestor) {
    return await this.iov42.getAsset(
      assetId,
      assetTypeId,
      requestor.getIdentityId(),
      requestor.getDelegatorId() as undefined
    )
  }

  async getIdentityClaim(subjectId: string, claim: string, requestor: Requestor) {
    return await this.iov42.getIdentityClaim(
      subjectId,
      requestor.getIdentityId(),
      claim,
      requestor.getDelegatorId() as undefined
    )
  }

  async getAssetTypeClaim(subjectId: string, claim: string, requestor: Requestor) {
    return await this.iov42.getAssetTypeClaim(
      subjectId,
      claim,
      requestor.getIdentityId(),
      requestor.getDelegatorId() as undefined
    )
  }

  async getAssetClaim(subjectId: string, subjectTypeId: string, claim: string, requestor: Requestor) {
    return await this.iov42.getAssetClaim(
      subjectId,
      subjectTypeId,
      claim,
      requestor.getIdentityId(),
      requestor.getDelegatorId() as undefined
    )
  }

  async getIdentityClaimEndorsement(
    subjectId: string,
    claim: string,
    endorserId: string,
    requestor: Requestor
  ) {
    return await this.iov42.getIdentityClaimEndorsement(
      subjectId,
      requestor.getIdentityId(),
      claim,
      endorserId,
      requestor.getDelegatorId() as undefined
    )
  }

  async getAssetTypeClaimEndorsement(
    subjectId: string,
    claim: string,
    endorserId: string,
    requestor: Requestor
  ) {
    return await this.iov42.getAssetTypeClaimEndorsement(
      subjectId,
      claim,
      endorserId,
      requestor.getIdentityId(),
      requestor.getDelegatorId() as undefined
    )
  }

  async getAssetClaimEndorsement(
    subjectId: string,
    subjectTypeId: string,
    claim: string,
    endorserId: string,
    requestor: Requestor
  ) {
    return await this.iov42.getAssetClaimEndorsement(
      subjectId,
      subjectTypeId,
      claim,
      endorserId,
      requestor.getIdentityId(),
      requestor.getDelegatorId() as undefined
    )
  }

  async getIdentity(requestor: Requestor) {
    return await this.iov42.getIdentity(requestor.getIdentityId())
  }

  async createIdentity(protocolId: ProtocolIdType, identityId: string) {
    const requestId = uuidv4()
    const actions = [
      {
        type: 'create-identity',
        signatories: null,
        requestId: requestId,
        identityId: identityId,
        protocolId,
      },
    ]
    const inputActions = {
      actions,
      title: `Creating identity "${identityId}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    const response = await this.execPluginActions(inputActions)
    const resp = {
      pubKeyBase64: null,
      prvKeyBase64: null,
      proof: response.proof,
      requestId: response.requestId,
    }
    return resp
  }

  async createAssetType(input: any, requestor: Requestor) {
    const requestId = uuidv4()
    const createAssetType: any = {
      type: 'create-asset-type',
      signatories: [requestor.getIdentityId()],
      requestId: requestId,
      assetTypeId: input.address,
      assetTypeType: input.type,
    }
    if (requestor.hasDelegator()) {
      createAssetType.delegatorIdentityId = requestor.getDelegatorId()
    }
    if (input.type === 'Quantifiable') {
      createAssetType.scale = Number(input.scale)
    }
    const inputActions = {
      actions: [createAssetType],
      title: `Creating ${input.type} asset type "${input.address}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async createAsset(input: any, requestor: Requestor) {
    const requestId = uuidv4()
    const createAsset: any = {
      type: 'create-asset',
      signatories: [requestor.getIdentityId()],
      requestId: requestId,
      assetId: input.address,
      assetTypeId: input.assetTypeId,
    }
    if (requestor.hasDelegator()) {
      createAsset.delegatorIdentityId = requestor.getDelegatorId()
    }
    if (input.quantity !== '') {
      createAsset.quantity = input.quantity
    }
    const inputActions = {
      actions: [createAsset],
      title: `Creating asset "${input.address}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async assignDelegate(delegator: StoredIdentity, delegate: StoredIdentity) {
    const requestId = uuidv4()
    const addDelegate = {
      type: 'add-delegate',
      signatories: [delegator.address, delegate.address],
      requestId: requestId,
      delegateIdentityId: delegate.address,
      identityId: delegator.address,
    }
    const inputActions = {
      actions: [addDelegate],
      title: `Adding delegate "${delegate.address}" to "${delegator.address}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async getHealthChecks() {
    return await this.iov42.getHealthChecks()
  }

  async createIdentityClaims(input: any, requestor: Requestor) {
    const requestId = uuidv4()
    const addIdentityClaims: any = {
      type: 'add-identity-claims',
      signatories: [requestor.getIdentityId()],
      requestId: requestId,
      claims: this.platformUtils.createClaimsHeader([input.plaintextClaim]),
      subjectId: input.subjectId,
    }
    if (requestor.hasDelegator()) {
      addIdentityClaims.delegatorIdentityId = requestor.getDelegatorId()
    }
    const inputActions = {
      actions: [addIdentityClaims],
      title: `Adding claim to identity "${input.subjectId}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async createAssetTypeClaims(input: any, requestor: Requestor) {
    const requestId = uuidv4()
    const addAssetTypeClaims: any = {
      type: 'add-asset-type-claims',
      signatories: [requestor.getIdentityId()],
      requestId: requestId,
      claims: this.platformUtils.createClaimsHeader([input.plaintextClaim]),
      subjectId: input.subjectId,
    }
    if (requestor.hasDelegator()) {
      addAssetTypeClaims.delegatorIdentityId = requestor.getDelegatorId()
    }
    const inputActions = {
      actions: [addAssetTypeClaims],
      title: `Adding claim to asset type "${input.subjectId}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async createAssetClaims(input: any, requestor: Requestor) {
    const requestId = uuidv4()
    const addAssetClaims: any = {
      type: 'add-asset-claims',
      signatories: [requestor.getIdentityId()],
      requestId: requestId,
      claims: this.platformUtils.createClaimsHeader([input.plaintextClaim]),
      subjectId: input.subjectId,
      subjectTypeId: input.subjectTypeId,
    }
    if (requestor.hasDelegator()) {
      addAssetClaims.delegatorIdentityId = requestor.getDelegatorId()
    }
    const inputActions = {
      actions: [addAssetClaims],
      title: `Adding claim to asset "${input.subjectId}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async createIdentityClaimEndorsement(input: any, requestor: Requestor) {
    const requestId = uuidv4()
    const delegatorId = requestor.getDelegatorId()
    const endorseIdentityClaims: any = {
      type: 'endorse-identity-claims',
      signatories: [requestor.getIdentityId()],
      requestId: requestId,
      endorsements: this.platformUtils.createClaimsHeader([input.plaintextClaim]),
      endorserId: delegatorId || requestor.getIdentityId(),
      subjectId: input.subjectId,
    }
    if (requestor.hasDelegator()) {
      endorseIdentityClaims.delegatorIdentityId = delegatorId
    }
    const inputActions = {
      actions: [endorseIdentityClaims],
      title: `Adding endorsement to claim "${input.plaintextClaim}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async createAssetTypeClaimEndorsement(input: any, requestor: Requestor) {
    const requestId = uuidv4()
    const delegatorId = requestor.getDelegatorId()
    const endorseAssetTypeClaims: any = {
      type: 'endorse-asset-type-claims',
      signatories: [requestor.getIdentityId()],
      requestId: requestId,
      endorsements: this.platformUtils.createClaimsHeader([input.plaintextClaim]),
      endorserId: delegatorId || requestor.getIdentityId(),
      subjectId: input.subjectId,
    }
    if (requestor.hasDelegator()) {
      endorseAssetTypeClaims.delegatorIdentityId = delegatorId
    }
    const inputActions = {
      actions: [endorseAssetTypeClaims],
      title: `Adding endorsement to claim "${input.plaintextClaim}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async createAssetClaimEndorsement(input: any, requestor: Requestor) {
    const requestId = uuidv4()
    const delegatorId = requestor.getDelegatorId()
    const endorseAssetClaims: any = {
      type: 'endorse-asset-claims',
      signatories: [requestor.getIdentityId(), input.claimant.authenticator.address],
      requestId: requestId,
      endorsements: this.platformUtils.createClaimsHeader([input.plaintextClaim]),
      endorserId: delegatorId || requestor.getIdentityId(),
      subjectId: input.subjectId,
      subjectTypeId: input.subjectTypeId,
    }
    if (requestor.hasDelegator()) {
      endorseAssetClaims.delegatorIdentityId = delegatorId
    }
    const inputActions = {
      actions: [endorseAssetClaims],
      title: `Adding endorsement to claim "${input.plaintextClaim}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async getProof(requestId: string, requestor: Requestor) {
    return await this.iov42.getProof(requestId, requestor.getIdentityId())
  }

  async transferOwnership(input: any, asset: StoredAsset, requestor: Requestor) {
    const requestId = uuidv4()
    const transfer = {
      assetId: asset.address,
      assetTypeId: asset.assetTypeId,
      fromIdentityId: asset.owner,
      toIdentityId: input.toIdentity,
      type: 'ownership',
    }
    const transferAssets: any = {
      type: 'transfer-assets',
      signatories: [requestor.getIdentityId()],
      requestId: requestId,
      transfers: [transfer],
    }
    if (requestor.hasDelegator()) {
      transferAssets.delegatorIdentityId = requestor.getDelegatorId()
    }
    const inputActions = {
      actions: [transferAssets],
      title: `Transfering unique asset ${asset.address} to identity "${input.toIdentity}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async transferQuantity(input: any, account: StoredAsset, requestor: Requestor) {
    const requestId = uuidv4()
    const transfer = {
      assetTypeId: account.assetTypeId,
      fromAssetId: account.address,
      quantity: input.quantity,
      toAssetId: input.toAsset,
      type: 'quantifiable',
    }
    const transferAssets: any = {
      type: 'transfer-assets',
      signatories: [requestor.getIdentityId()],
      requestId: requestId,
      transfers: [transfer],
    }
    if (requestor.hasDelegator()) {
      transferAssets.delegatorIdentityId = requestor.getDelegatorId()
    }
    const inputActions = {
      actions: [transferAssets],
      title: `Transfering assets from account ${account.address} to account "${input.toAsset}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }

  async addQuantity(input: any, account: StoredAsset, requestor: Requestor) {
    const requestId = uuidv4()
    const addQuantity: any = {
      type: 'add-account-quantity',
      signatories: [requestor.getIdentityId()],
      requestId: requestId,
      assetId: account.address,
      assetTypeId: account.assetTypeId,
      quantity: input.quantity,
    }
    if (requestor.hasDelegator()) {
      addQuantity.delegatorIdentityId = requestor.getDelegatorId()
    }
    const inputActions = {
      actions: [addQuantity],
      title: `Adding ${input.quantity} quantity to account "${account.address}"`,
      subTitle: 'Triggered by the iov42 data browser',
    }
    return await this.execPluginActions(inputActions)
  }
}

const clientExt = new ClientExt()

export { clientExt }
