import React from 'react'
import { Chip } from '@material-ui/core'
import { Cancel, CheckCircle } from '@material-ui/icons'

export type ProofStatus = 'Ok' | 'Not ok'

export interface StatusChipProps {
  status: ProofStatus
  className?: string
}

export const StatusChip: React.FC<StatusChipProps> = ({ status, className }) => {
  return (
    <Chip
      className={className}
      size="small"
      icon={status === 'Ok' ? <CheckCircle /> : <Cancel />}
      label={status}
      color={status === 'Ok' ? 'primary' : 'secondary'}
    />
  )
}
