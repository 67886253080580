import React, { FunctionComponent } from 'react'
import { useGuide } from '../utils/useGuide'
import Joyride from 'react-joyride'
import { UserGuidesType } from '../client/guide'

interface UserGuideProps {
  id: UserGuidesType
}

export const UserGuide: FunctionComponent<UserGuideProps> = ({ id }) => {
  const guide = useGuide(id)

  return guide.run ? (
    <Joyride
      continuous={true}
      steps={guide.steps}
      run={guide.run}
      scrollToFirstStep={true}
      showProgress={false}
      showSkipButton={true}
      styles={{
        options: {
          primaryColor: '#00251c',
        },
      }}
    />
  ) : null
}
