import React from 'react'
import { guideClient, UserGuidesType } from '../client/guide'
import { useSelector } from 'react-redux'
import { getUserPreference } from '../modules/userPreference'

export function useGuide(guide: UserGuidesType) {
  const userPreference = useSelector(getUserPreference)
  const [state, setState] = React.useState({
    run: false,
    steps: [],
  })
  guideClient.getGuide(guide).then((guide: any) => {
    if (!state.run && userPreference.useGuide && guide.run) {
      setState(guide)
    }
  })
  return state
}
