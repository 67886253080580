import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Field, Form, Formik, FormikValues } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getCurrentProof } from 'modules/proof'
import { getSelectedIdentity } from 'modules/selectedIdentity'
import ReactJson from 'react-json-view'
import { Button, FormControl, TextField } from '@material-ui/core'
import { StoredIdentity } from 'model'
import DisplayError from './DisplayError'
import { useReadProof } from '../utils/useReadProof'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { PlatformUtils } from 'core-sdk-typescript'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { ThumbDown, ThumbUp } from '@material-ui/icons'

const platformUtils = new PlatformUtils()

async function proofCheck(proofJson: string) {
  const validation: any = await platformUtils.validateProof(proofJson)
  return {
    isValid: validation.signatories.every((s: any) => s.valid),
    signatories: validation.signatories,
  }
}

export interface ProofDialogProps {
  proofJson: string
  open: boolean
  onClose: (value: string) => void
}

function ProofDialog(props: ProofDialogProps) {
  const { onClose, open, proofJson } = props
  const [state, setState] = useState({
    message: '',
    signatories: [],
  })
  const handleClose = () => {
    onClose('Close Dialog')
  }

  useEffect(() => {
    if (proofJson) {
      proofCheck(proofJson).then((check: any) => {
        setState({
          message: check.isValid ? "The proof's signature is valid" : "The proof's signature is invalid",
          signatories: check.signatories,
        })
      })
    }
  }, [proofJson, setState])

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Proof validation</DialogTitle>
      <Typography style={{ marginLeft: 10 }}>
        {state.message}
        <br />
        <br />
      </Typography>
      <Typography style={{ marginLeft: 10 }}>Signatories:</Typography>
      <List style={{ marginLeft: 10 }}>
        {state.signatories.map((el: any) => (
          <ListItem key={el.identityId}>
            <ListItemText style={{ width: 300 }}>{el.identityId}</ListItemText>
            <ListItemIcon style={{ color: el.valid ? 'green' : 'red' }}>
              {el.valid ? <ThumbUp /> : <ThumbDown />}
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
      <Button onClick={() => onClose('Close Dialog')}>Close</Button>
    </Dialog>
  )
}

export interface ContentProps {}

const ViewRequest: React.FC<ContentProps> = () => {
  const [result, error, submit] = useReadProof()
  const { id } = useParams() as {
    id: string
  }
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value: string) => {
    setOpen(false)
  }

  const proof = useSelector(getCurrentProof)
  const selectedIdentity: StoredIdentity = useSelector(getSelectedIdentity) as StoredIdentity
  const dispatch = useDispatch()
  const formRef = useRef<FormikValues>() as any

  useEffect(() => {
    if (selectedIdentity && id !== ':id' && formRef.current.values.requestId !== '') {
      formRef.current.handleSubmit()
    }
  }, [id, dispatch, selectedIdentity, formRef])

  return (
    <div style={{ width: '100%' }}>
      <Formik initialValues={{ requestId: id !== ':id' ? id : '' }} onSubmit={submit} innerRef={formRef}>
        <Form>
          <div className="requestId">
            <FormControl style={{ display: 'block' }}>
              <Field
                as={TextField}
                name="requestId"
                type="text"
                label="RequestId"
                style={{ width: '320px' }}
              />
            </FormControl>
          </div>
          <div style={{ paddingTop: 15 }}>
            <Button variant="contained" color="primary" type="submit" style={{ marginBottom: '10px' }}>
              {'Get proof'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: '10px', marginBottom: '10px' }}
              onClick={handleClickOpen}
            >
              {'Validate proof'}
            </Button>
            <ProofDialog proofJson={proof} open={open} onClose={handleClose} />
          </div>
        </Form>
      </Formik>
      {proof ? (
        <ReactJson src={JSON.parse(proof)} theme="bright" displayDataTypes={false} collapsed={2} />
      ) : (
        <Fragment />
      )}
      <DisplayError errorTitle={error} error={result.error} />
    </div>
  )
}

export default ViewRequest
