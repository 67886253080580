import React from 'react'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useParams } from 'react-router'
import Link from '@material-ui/core/Link'
import Locations from '../Locations'
import { capitaliseFirst } from '../utils/arrayAndStringsUtils'

export interface ContentProps {}

const ViewClaim: React.FC<ContentProps> = _ => {
  const { requestId } = useParams() as {
    requestId: string
  }
  const claims: [{ [x: string]: any }] = JSON.parse(localStorage.getItem('claims') || '[]')
  const claim = claims.filter(i => i['requestId'] === requestId)[0]
  return (
    <Typography color="textSecondary" align="center">
      {claim === undefined ? (
        `Claim ${requestId} not found`
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Hashed claim</b>
                </TableCell>
                <TableCell>{claim.hashedClaim}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Plaintext claim</b>
                </TableCell>
                <TableCell>{claim.plaintextClaim}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Type of claim</b>
                </TableCell>
                <TableCell>{capitaliseFirst(claim.subjectType)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Request ID</b>
                </TableCell>
                <TableCell>
                  <Link href={`${Locations.ViewRequest.buildUri({ id: claim.requestId })}`}>
                    {claim.requestId}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Created by</b>
                </TableCell>
                <TableCell>{claim.claimant.authenticator.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Via delegate</b>
                </TableCell>
                <TableCell>{claim.delegateIdentityId}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Typography>
  )
}

export default ViewClaim
