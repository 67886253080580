import React from 'react'
import Typography from '@material-ui/core/Typography'
import { PLAYGROUND_GUIDE } from '../client/guide'
import { UserGuide } from './UserGuide'

export interface ContentProps {}

const Home: React.FC<ContentProps> = _ => {
  return (
    <div>
      <UserGuide id={PLAYGROUND_GUIDE} />
      <Typography color="textSecondary" align="center">
        Please select one of the options from the menu bar.
      </Typography>
    </div>
  )
}

export default Home
